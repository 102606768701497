import {
  FETCH_MORE_DAMAGE_LIST,
  FETCH_DAMAGE_LIST,
  APPLY_DAMAGE_SORTING,
  APPLY_DAMAGE_FILTER,
  PUSH_DAMAGE_TO_LIST,
  REMOVE_DAMAGE_FROM_LIST,
  PATCH_DAMAGE_IN_LIST
} from "./actions";
import {
  SET_DAMAGE_LIST,
  SET_FETCHING,
  SET_SORTING,
  SET_FILTER,
  SET_DAMAGE_CANLOADMORE,
  APPEND_DAMAGE_LIST,
  SET_ERROR
} from "./mutations";
import apiService from "../../../services/api";
import merge from "deepmerge";

const loadLimit = 20;

const state = {
  isFetching: false,
  canLoadMore: true,
  error: null,
  damages: [],
  sort: { field: "createdAt", order: -1 },
  filter: null
};

const getters = {};

const actions = {
  async [PATCH_DAMAGE_IN_LIST](
    context,
    { damageId, payload, replace = false }
  ) {
    if (!context.rootGetters.isAuthenticated) {
      return context.commit(SET_DAMAGE_LIST, []);
    }
    context.commit(
      SET_DAMAGE_LIST,
      context.state.damages.map(damage => {
        if (damage._id === damageId) {
          return replace
            ? payload
            : merge(damage, payload, { arrayMerge: (_, src) => src });
        }
        return damage;
      })
    );
  },
  async [REMOVE_DAMAGE_FROM_LIST](context, { damageId }) {
    if (!context.rootGetters.isAuthenticated) {
      return context.commit(SET_DAMAGE_LIST, []);
    }
    context.commit(
      SET_DAMAGE_LIST,
      context.state.damages.filter(damage => damage._id !== damageId)
    );
  },
  async [FETCH_DAMAGE_LIST](context) {
    if (!context.rootGetters.isAuthenticated) {
      return context.commit(SET_DAMAGE_LIST, []);
    }
    context.commit(SET_ERROR, null);
    context.commit(SET_FETCHING, true);
    let damages;
    try {
      damages = await apiService.getDamageList({
        sort: context.state.sort,
        filter: context.state.filter,
        limit: loadLimit
      });
    } catch (error) {
      damages = [];
      context.commit(SET_ERROR, error.response.data);
    }
    context.commit(SET_DAMAGE_CANLOADMORE, loadLimit === damages.length);
    context.commit(SET_DAMAGE_LIST, damages);
    context.commit(SET_FETCHING, false);
  },
  async [FETCH_MORE_DAMAGE_LIST](context) {
    if (!context.rootGetters.isAuthenticated) {
      return context.commit(SET_DAMAGE_LIST, []);
    }
    if (!context.state.damages.length) {
      return context.dispatch(FETCH_DAMAGE_LIST);
    }
    if (!context.state.canLoadMore) {
      return;
    }
    const lastId = context.state.damages[context.state.damages.length - 1]._id;
    context.commit(SET_FETCHING, true);
    const damages = await apiService.getDamageList({
      sort: context.state.sort,
      filter: context.state.filter,
      limit: loadLimit,
      lastId
    });
    context.commit(SET_DAMAGE_CANLOADMORE, loadLimit === damages.length);
    context.commit(APPEND_DAMAGE_LIST, damages);
    context.commit(SET_FETCHING, false);
  },
  [APPLY_DAMAGE_SORTING](context, sort) {
    context.commit(SET_SORTING, sort);
    return context.dispatch(FETCH_DAMAGE_LIST);
  },
  [APPLY_DAMAGE_FILTER](context, filter) {
    context.commit(SET_FILTER, filter);
    return context.dispatch(FETCH_DAMAGE_LIST);
  },
  [PUSH_DAMAGE_TO_LIST](context, { damage, index }) {
    const arr = [...context.state.damages];
    arr.splice(index, 0, damage);
    context.commit(SET_DAMAGE_LIST, arr);
  }
};

const mutations = {
  [SET_FETCHING](state, status) {
    state.isFetching = status;
  },
  [APPEND_DAMAGE_LIST](state, damages) {
    state.damages = [
      ...state.damages,
      ...damages.filter(d => !state.damages.find(x => d._id === x._id))
    ];
  },
  [SET_DAMAGE_CANLOADMORE](state, canLoadMore) {
    state.canLoadMore = canLoadMore;
  },
  [SET_DAMAGE_LIST](state, damages) {
    state.damages = damages;
  },
  [SET_SORTING](state, sort) {
    state.sort = sort;
  },
  [SET_FILTER](state, filter) {
    state.filter = filter;
  },
  [SET_ERROR](state, error) {
    state.error = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
