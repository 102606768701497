import { FETCH_DAMAGE, UPDATE_DAMAGE, PATCH_DAMAGE } from "./actions";
import { SET_DAMAGE, SET_FETCHING, SET_ERROR } from "./mutations";
import apiService from "../../../services/api";
import merge from "deepmerge";

const state = {
  error: {
    fetching: null
  },
  isFetching: false,
  damage: null
};

const getters = {};

const actions = {
  async [FETCH_DAMAGE](context, damageId) {
    if (!context.rootGetters.isAuthenticated) {
      return context.commit(SET_DAMAGE, null);
    }
    context.commit(SET_ERROR, "fetching", null);
    context.commit(SET_DAMAGE, null);
    context.commit(SET_FETCHING, true);
    try {
      context.commit(SET_DAMAGE, await apiService.getDamage(damageId));
    } catch (error) {
      context.commit(SET_ERROR, "fetching", error);
    }
    context.commit(SET_FETCHING, false);
  },
  async [UPDATE_DAMAGE](context) {
    if (!context.rootGetters.isAuthenticated || context.damage === null) {
      return context.commit(SET_DAMAGE, null);
    }
    context.commit(SET_FETCHING, true);
    context.commit(
      SET_DAMAGE,
      await apiService.getDamage(context.state.damage._id)
    );
    context.commit(SET_FETCHING, false);
  },
  async [PATCH_DAMAGE](context, { damageId, payload, replace = false }) {
    if (!context.rootGetters.isAuthenticated || !context.state.damage) {
      return context.commit(SET_DAMAGE, null);
    }
    if (context.state.damage._id !== damageId) {
      return;
    }
    const patched = replace
      ? payload
      : merge(context.state.damage, payload, {
          arrayMerge: (_, src) => src
        });
    context.commit(SET_DAMAGE, patched);
  }
};

const mutations = {
  [SET_FETCHING](state, status) {
    state.isFetching = status;
  },
  [SET_ERROR](state, type, err) {
    state.error[type] = err;
  },
  [SET_DAMAGE](state, damage) {
    state.damage = damage;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
