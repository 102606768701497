<template>
  <el-dialog
    :visible="open"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    width="350px"
  >
    <div class="wrap">
      <div class="info">
        <h2>Testphase</h2>
        Liebe Nutzerin, lieber Nutzer, wir weisen daraufhin, dass dies nur eine
        reine Testphase ist und zur Weiterentwicklung und Verbesserung unserer
        Internetplattform dient. Die Nutzung und alle damit verbundenen
        Interaktionen sind unverbindlich.
      </div>
      <div class="actions">
        <MdButton class="md-primary" @click="$emit('close')"
          >Ok, Verstanden!</MdButton
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "TestphaseConfirm",
  props: ["open"],
};
</script>

<style lang="scss">
.el-dialog__wrapper.testphase {
  border-radius: 18px;

  .el-dialog {
    overflow: hidden;
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  .wrap {
    height: 100%;

    .info {
      padding: 20px;
    }

    .actions {
      text-align: center;
      margin-bottom: 10px;
    }
  }
}
</style>
