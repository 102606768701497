export default {
  newOfferOnDamage: "NOTIFICATION_NEW_OFFER_ON_DAMAGE",
  newCounterOfferOnDamage: "NOTIFICATION_NEW_COUNTER_OFFER_ON_DAMAGE",
  newTurboOfferOnDamage: "NOTIFICATION_NEW_TURBO_OFFER_ON_DAMAGE",
  offerAccepted: "NOTIFICATION_OFFER_ACCEPTED",
  outBidCounterOffer: "NOTIFICATION_OUT_BID_COUNTER_OFFER",
  counterOfferInvalidatedAndDeleted:
    "NOTIFICATION_COUNTER_OFFER_INVALIDATED_AND_DELETED",
  repairStatusChange: "NOTIFICATION_REPAIR_STATUS_CHANGE"
};
