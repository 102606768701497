import { FETCH_DAMAGES, PATCH_DAMAGES_DAMAGE } from "./actions";
import { SET_DAMAGES, SET_FETCHING } from "./mutations";
import apiService from "../../../services/api";
import merge from "deepmerge";

const state = {
  isFetching: false,
  damages: []
};

const getters = {};

const actions = {
  async [FETCH_DAMAGES](context) {
    if (!context.rootGetters.isAuthenticated) {
      return context.commit(SET_DAMAGES, []);
    }
    context.commit(SET_FETCHING, true);
    context.commit(SET_DAMAGES, await apiService.getDamages());
    context.commit(SET_FETCHING, false);
  },
  async [PATCH_DAMAGES_DAMAGE](context, { damageId, payload }) {
    if (!context.rootGetters.isAuthenticated) {
      return context.commit(SET_DAMAGES, []);
    }
    context.commit(
      SET_DAMAGES,
      context.state.damages.map(damage => {
        if (damage._id === damageId) {
          return merge(damage, payload, { arrayMerge: (_, src) => src });
        }
        return damage;
      })
    );
  }
};

const mutations = {
  [SET_FETCHING](state, status) {
    state.isFetching = status;
  },
  [SET_DAMAGES](state, damages) {
    state.damages = damages;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
