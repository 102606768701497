import {
  FETCH_DAMAGE_LIST_FAVORITES,
  PUSH_DAMAGE_TO_LIST_FAVORITES,
  REMOVE_DAMAGE_FROM_LIST_FAVORITES,
  PATCH_DAMAGE_IN_LIST_FAVORITES
} from "./actions";
import { SET_DAMAGE_LIST_FAVORITES, SET_FETCHING } from "./mutations";
import apiService from "../../../services/api";
import merge from "deepmerge";

const state = {
  isFetching: false,
  damages: []
};

const getters = {};

const actions = {
  async [FETCH_DAMAGE_LIST_FAVORITES](context) {
    if (!context.rootGetters.isAuthenticated) {
      return context.commit(SET_DAMAGE_LIST_FAVORITES, []);
    }
    context.commit(SET_FETCHING, true);
    context.commit(
      SET_DAMAGE_LIST_FAVORITES,
      await apiService.getDamageListFavorites()
    );
    context.commit(SET_FETCHING, false);
  },
  async [PUSH_DAMAGE_TO_LIST_FAVORITES](context, damageId) {
    if (!context.rootGetters.isAuthenticated) {
      return context.commit(SET_DAMAGE_LIST_FAVORITES, []);
    }
    const newDamage = await apiService.addDamageListFavorite(damageId);
    console.log(newDamage);
    context.commit(SET_DAMAGE_LIST_FAVORITES, [
      newDamage,
      ...context.state.damages
    ]);
  },
  async [REMOVE_DAMAGE_FROM_LIST_FAVORITES](
    context,
    { damageId, onlyFrontend = false }
  ) {
    if (!context.rootGetters.isAuthenticated) {
      return context.commit(SET_DAMAGE_LIST_FAVORITES, []);
    }
    if (!onlyFrontend) {
      await apiService.removeDamageListFavorite(damageId);
    }
    context.commit(SET_DAMAGE_LIST_FAVORITES, [
      ...context.state.damages.filter(damage => damage._id !== damageId)
    ]);
  },
  async [PATCH_DAMAGE_IN_LIST_FAVORITES](
    context,
    { damageId, payload, replace = false }
  ) {
    if (!context.rootGetters.isAuthenticated) {
      return context.commit(SET_DAMAGE_LIST_FAVORITES, []);
    }
    context.commit(
      SET_DAMAGE_LIST_FAVORITES,
      context.state.damages.map(damage => {
        if (damage._id === damageId) {
          return replace
            ? payload
            : merge(damage, payload, { arrayMerge: (_, src) => src });
        }
        return damage;
      })
    );
  }
};

const mutations = {
  [SET_FETCHING](state, status) {
    state.isFetching = status;
  },
  [SET_DAMAGE_LIST_FAVORITES](state, damages) {
    state.damages = damages;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
