<template>
  <div>
    <h2>Fotos des Schadens</h2>

    <div class="top-note">
      <div class="note-body">
        <div class="qr">
          <qrcode-vue
            v-if="uploadLink"
            :value="uploadLink"
            :size="120"
            level="H"
          />
          <md-progress-spinner
            v-else
            md-mode="indeterminate"
          ></md-progress-spinner>
        </div>
        <div class="text">
          <span class="title">Upload direkt vom Smartphone</span>
          <ul>
            <li>Scanne den QR-Code</li>
            <li>Suche die Bilder aus deiner Galerie aus</li>
            <li>Lade sie direkt vom Smartphone hoch</li>
          </ul>
        </div>
      </div>
    </div>

    <el-collapse class="accordion" v-model="activeCollapsItem" accordion>
      <el-collapse-item
        v-for="(image, index) in imageDefinitions"
        :name="`${index}`"
        :key="index"
      >
        <template slot="title">
          <span :class="{ status: 1, success: !!images[`c${index}`] }">
            <md-icon>{{
              !!images[`c${index}`] ? "check_box" : "check_box_outline_blank"
            }}</md-icon>
          </span>
          {{ image.title }}
        </template>
        <div class="image-wrap">
          <p class="description">
            <span class="title">Tipp:</span
            ><VueMarkdown
              class="markdown-image-desc"
              :source="image.description"
            ></VueMarkdown>
          </p>
          <div class="example-real-container">
            <div
              class="example"
              :style="{ 'background-image': `url(${image.exampleSrc})` }"
            >
              <div class="title">Beispiel</div>
            </div>
            <UploadContainer
              @change="setImage(index, $event)"
              @converting="setLoading(index, $event)"
              accept="image/*"
              class="image-container"
              :key="index"
            >
              <div
                class="image"
                :style="{
                  'background-image': `url(${images[`c${index}`].src})`,
                }"
                v-if="images[`c${index}`]"
              ></div>
              <div
                class="image-preview"
                v-else-if="peerUploadPreviews[`c${index}`]"
              >
                <Blurhash
                  :height="180"
                  :width="200"
                  :src="peerUploadPreviews[`c${index}`].blurhash"
                />
              </div>
              <div class="placeholder" v-else>
                <md-icon class="md-size-2x icon">add_a_photo</md-icon>
                <span>Foto hinzufügen</span>
              </div>
              <span v-if="!!images[`c${index}`]" class="uploaded-photo-flag">
                <md-icon class="icon">done</md-icon>
              </span>
              <div v-if="!!isLoading[index]" class="preloader">
                <md-icon class="md-size-2x icon">engineering</md-icon>
                <span>Bild wird verarbeitet, eine sekunde bitte...</span>
              </div>
            </UploadContainer>
          </div>
          <md-button
            v-if="index !== imageDefinitions.length - 1"
            @click="activeCollapsItem = `${index + 1}`"
            :disabled="!images[`c${index}`]"
            class="md-primary"
            >Nächstes Foto</md-button
          >
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import constants from "@/constants";
import socket from "@/services/socket";

import VueMarkdown from "vue-markdown-render";
import QrcodeVue from "qrcode.vue";
import UploadContainer from "@/components/UploadContainer";
import Blurhash from "@/components/Blurhash";

// add different configs here for different images‚
const MIN_IMAGES = 4;
const IMAGE_DEFINITIONS = [
  {
    title: "Close-Up (Eine Handlänge)",
    description:
      "Bitte halte dein Mobiltelefon/Fotoapparat etwa **eine Handlänge** vom Schaden entfernt und mache ein Foto.",
    exampleSrc: "/images/hand.jpg",
  },
  {
    title: "Mittlere Entfernung 1 (Halbe Armlänge)",
    description:
      "Bitte halte dein Mobiltelefon/Fotoapparat etwa **eine halbe Armlänge** vom Schaden entfernt und mache ein Foto.",
    exampleSrc: "/images/half_arm.jpg",
  },
  {
    title: "Mittlere Entfernung 2 (Eine Armlänge)",
    description:
      "Bitte halte dein Mobiltelefon/Fotoapparat etwa **eine ganze Armlänge** vom Schaden entfernt und mache ein Foto.",
    exampleSrc: "/images/arm.jpg",
  },
  {
    title: "Gesamt Foto (2 Meter Abstand)",
    description:
      "Bitte entferne dich etwa 2 Schritte von deinem Fahrzeug und mache ein Foto",
    exampleSrc: "/images/2_meters.jpg",
  },
];
const IMAGE_DEFINITIONS_RIM = [
  {
    title: "Close-Up (Eine Handlänge)",
    description:
      "Bitte halte dein Mobiltelefon/Fotoapparat etwa **eine Handlänge** vom Schaden entfernt und mache ein Foto.",
    exampleSrc: "/images/rim_hand.jpg",
  },
  {
    title: "Mittlere Entfernung 1 (Halbe Armlänge)",
    description:
      "Bitte halte dein Mobiltelefon/Fotoapparat etwa **eine halbe Armlänge** vom Schaden entfernt und mache ein Foto.",
    exampleSrc: "/images/rim_half_arm.jpg",
  },
  {
    title: "Mittlere Entfernung 2 (Eine Armlänge)",
    description:
      "Bitte halte dein Mobiltelefon/Fotoapparat etwa **eine ganze Armlänge** vom Schaden entfernt und mache ein Foto.",
    exampleSrc: "/images/rim_arm.jpg",
  },
  {
    title: "Gesamt Foto (2 Meter Abstand)",
    description:
      "Bitte entferne dich etwa 2 Schritte von deinem Fahrzeug und mache ein Foto",
    exampleSrc: "/images/rim_2_meters.jpg",
  },
];

const getImageFromFile = (file) =>
  new Promise((r) => {
    const reader = new FileReader();
    reader.onload = (event) => r(event.target.result);
    reader.onerror = (err) => console.error(err);
    reader.readAsDataURL(file);
  });

export default {
  name: "PhotosSlide",
  props: ["uploadKey", "damageType"],
  data() {
    return {
      imageDefinitions: IMAGE_DEFINITIONS,
      isLoading: [...Array(MIN_IMAGES).keys()].map(() => false),
      images: {},
      peerUploadPreviews: {},
      activeCollapsItem: "0",
    };
  },
  computed: {
    uploadLink() {
      if (!this.uploadKey) return null;
      return window.location.origin + "/upload/" + this.uploadKey;
    },
  },
  components: {
    UploadContainer,
    VueMarkdown,
    QrcodeVue,
    Blurhash,
  },
  watch: {
    damageType() {
      const imageDefinitions =
      this.damageType === constants.damageType.rim
        ? IMAGE_DEFINITIONS_RIM
        : IMAGE_DEFINITIONS;
      this.imageDefinitions = imageDefinitions;
    },
    images() {
      let images = Object.values(this.images).map((image) => image.files[0]);
      console.log(images);
      const valid = images.length >= MIN_IMAGES && images.every((i) => i);
      this.$emit("change", {
        valid,
        data: {
          images,
        },
      });
    },
  },
  created() {
    socket.get().on(constants.socketEvents.uploadFile, this.onPeerAddedImage);
  },
  beforeDestroy() {
    socket.get().off(constants.socketEvents.uploadFile, this.onPeerAddedImage);
  },
  methods: {
    async setImage(index, { files }) {
      this.images = {
        ...this.images,
        [`c${index}`]: {
          files,
          src: await getImageFromFile(files[0]),
        },
      };
    },
    async setLoading(index, isLoading) {
      let newIsLoading = [...this.isLoading];
      newIsLoading[index] = isLoading;
      this.isLoading = newIsLoading;
    },
    async onPeerAddedImage({ key, data }) {
      if (key != this.uploadKey) return;
      if (data.type == "preview") {
        this.peerUploadPreviews = {
          ...this.peerUploadPreviews,
          [`c${data.index}`]: {
            blurhash: data.blurhash,
          },
        };
      } else if (data.type == "full") {
        const buffer = Uint8Array.from(atob(data.image), (c) =>
          c.charCodeAt(0),
        );
        const file = new File([buffer], data.name, {
          type: data.mimeType,
        });
        this.images = {
          ...this.images,
          [`c${data.index}`]: {
            files: [file],
            src: `data:${data.mimeType};base64,${data.image}`,
          },
        };
      }
    },
  },
};
</script>

<style lang="scss">
.markdown-image-desc {
  p {
    margin: 0;
  }

  strong {
    color: var(--md-theme-default-primary, #448aff);
  }
}
</style>

<style scoped lang="scss">
h2 {
  margin: 0;
}

.top-note {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-top: 12px;
  padding: 12px;

  .note-body {
    display: flex;
    justify-content: flex-start;

    .qr {
      background: white;
      border-radius: 12px;
      margin-right: 12px;
      width: 140px;
      height: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .text {
      padding: 5px 0;
      border: 2px solid rgb(230, 230, 230);
      color: rgb(102, 102, 102);
      border-radius: 12px;
      font-size: 14px;
      text-align: left;

      .title {
        font-weight: bold;
        margin-right: 7px;
      }

      ul {
        padding-left: 24px;
      }
    }
  }
}

.accordion {
  margin-top: 20px;
}

.status {
  i {
    color: rgb(197, 197, 197) !important;
    margin: -3px 5px 0 0;
    font-size: 18px !important;
  }

  &.success i {
    color: var(--md-theme-default-primary, #448aff) !important;
  }
}

.image-wrap {
  text-align: center;

  .description {
    margin: 5px 20px 20px 20px;
    padding: 10px 15px;
    border: 2px solid rgb(230, 230, 230);
    color: rgb(102, 102, 102);
    border-radius: 12px;
    font-size: 14px;
    text-align: left;

    .title {
      font-weight: bold;
      margin-right: 7px;
    }
  }

  .example-real-container {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 30px;

    .example {
      position: relative;
      display: block;
      height: 180px;
      width: 200px;
      background: rgb(233, 233, 233);
      border-radius: 12px;
      background-size: cover;
      background-position: center center;

      .title {
        position: absolute;
        bottom: 10px;
        left: calc(50% - 40px);
        width: 80px;
        padding: 4px 0;
        background: black;
        text-align: center;
        color: white;
        border-radius: 35px;
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
}

.image-container {
  position: relative;
  display: block;
  height: 180px;
  width: 200px;
  background: rgb(233, 233, 233);
  cursor: pointer;
  border-radius: 12px;

  .uploaded-photo-flag {
    position: absolute;
    left: calc(50% - 30px);
    top: -14px;
    background: #63df92;
    padding: 2px 0;
    border-radius: 20px;
    width: 60px;
    text-align: center;
    box-shadow: 2px 1px 12px rgba(45, 100, 57, 0.2);

    i {
      color: white !important;
    }
  }

  .placeholder {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    i {
      margin: 10px 0;
    }

    span {
      color: rgb(179, 179, 179);
      transition: ease 0.1s;
    }

    &:hover {
      span {
        color: rgb(107, 107, 107);
      }
    }
  }

  .preloader {
    position: absolute;
    top: 12px;
    bottom: 12px;
    left: 12px;
    right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(43, 43, 43, 0.87);
    border-radius: 12px;

    i {
      color: white !important;
      margin: 10px 0;
    }

    span {
      color: rgb(179, 179, 179);
      transition: ease 0.1s;
    }
  }

  .image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px solid #63df92;
    border-radius: 12px;
    padding: 2px;
    background-color: white;
    background-size: cover;
    background-position: center center;
    box-shadow: 2px 1px 12px rgba(45, 100, 57, 0.5);
  }

  .image-preview {
    position: relative;
    border-radius: 12px;
    overflow: hidden;

    &::after {
      position: absolute;
      content: "Wird geladen ...";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width: 740px) {
  .top-note {
    display: none;
  }
}
</style>
