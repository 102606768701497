<template>
  <div id="app">
    <!-- <div v-if="!(authIsLoaded && !userDataIsFetching)" id="preloader">
      <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
    </div>-->
    <AppHeader
      v-if="shouldShowHeader"
      @brandclick="$router.push('/')"
      @loginclick="openLoginDialog"
      @test-phase-click="testphaseConfirmOpen = true"
      @myaccountclick="$router.push('/my-account')"
      @adddamageclick="openDamageDialog"
      @logoutclick="logout"
    />
    <Error v-if="userDataError" />
    <VBarContainer className="maincontent">
      <router-view v-if="authIsLoaded && !userDataIsFetching" />
      <AddDamageDialog :open="addDamageDialogOpen" @close="closeDamageDialog" />
    </VBarContainer>
    <CookieLaw
      storageType="cookies"
      :cookieOptions="cookieOptions"
      buttonText="Verstanden!"
      theme="base"
    >
      <div slot="message">
        Wir nutzen Cookies, um unsere Dienste zu erbringen und zu verbessern.
        Mit Nutzung dieser Seite akzeptieren Sie Cookies.
      </div>
    </CookieLaw>
    <TestphaseConfirm
      :open="testphaseConfirmOpen"
      @close="testphaseConfirmOpen = false"
    />
    <!-- <md-button
      v-if="!isGarageAccount && shouldShowHeader"
      @click="openDamageDialog"
      class="damage-button mobile md-raised"
    >
      Schaden hinzufügen
    </md-button> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { OPEN_ADD_DAMAGE_DIALOG } from "@/vuex/modules/internal/actions";
import constants from "@/constants";
import Error from "@/components/Error.vue";
import VBarContainer from "@/components/VBarContainer";
import AppHeader from "@/components/AppHeader.vue";
import AddDamageDialog from "@/components/AddDamageDialog";
import TestphaseConfirm from "@/components/TestphaseConfirm";
import auth from "@/services/auth";
import CookieLaw from "vue-cookie-law";

const COOKIE_DOMAINS = {
  production: "bynand.de",
  default: window.location.hostname,
};

const COOKIE_DOMAIN =
  COOKIE_DOMAINS[process.env.NODE_ENV] || COOKIE_DOMAINS["default"];

export default {
  name: "App",
  computed: {
    ...mapState({
      addDamageDialogOpen: (state) => state.internal.data.addDamageDialogOpen,
      authIsLoaded: (state) => state.auth.isLoaded,
      accountType: (state) => state.userdata.accountType,
      userDataIsFetching: (state) => state.userdata.isFetching,
      userDataError: (state) => state.userdata.error,
    }),
    shouldShowHeader() {
      return (
        !this.userDataError &&
        this.authIsLoaded &&
        !this.userDataIsFetching &&
        !this.noHeader
      );
    },
    isGarageAccount() {
      return this.accountType === constants.accountType.garage;
    },
  },
  data: () => ({
    loginDialogOpen: false,
    initialPreloaderVisible: true,
    testphaseConfirmOpen: false,
    noHeader: false,
    cookieOptions: {
      domain: COOKIE_DOMAIN,
      expires: "1Y",
    },
  }),
  components: {
    AppHeader,
    AddDamageDialog,
    VBarContainer,
    Error,
    TestphaseConfirm,
    CookieLaw,
  },
  created() {
    this.$material.locale.firstDayOfAWeek = 1;
    this.$material.locale.dateFormat = "dd.MM.yyyy";
    this.$router.afterEach((to) => {
      this.noHeader = to.meta.noHeader;
      if (to.name === "home" && to.hash == "#login") {
        this.openLoginDialog();
      }
    });
  },
  methods: {
    afterLogin() {
      this.$router.push("/dashboard");
    },
    logout() {
      auth.logout();
      this.$router.push("/");
    },
    openLoginDialog() {
      this.loginDialogOpen = true;
    },
    closeLoginDialog() {
      this.loginDialogOpen = false;
    },
    openDamageDialog() {
      this.$store.dispatch(OPEN_ADD_DAMAGE_DIALOG, true);
    },
    closeDamageDialog() {
      this.$store.dispatch(OPEN_ADD_DAMAGE_DIALOG, false);
    },
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
  background: #f4f6f8;
  overflow: hidden;
}
.maincontent {
  flex-grow: 1;
  overflow: auto;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}
#preloader {
  background-color: white;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.md-menu-content {
  z-index: 12;
}
.dashboard-view {
  max-width: 1200px;
  margin: 60px auto 80px auto;
  padding: 0 30px;
}

.center {
  text-align: center;
}

// Disgusting fixes -> Todo: just get rid of this material lib.

.md-menu-content {
  border-radius: 8px;
  overflow: hidden;
}

.md-ripple {
  width: auto;
}

.md-field {
  min-height: 0;
}

h1,
h2 {
  font-family: "Comfortaa", "Avenir", Helvetica, Arial, sans-serif;
}

.el-dialog {
  border-radius: 14px;
}

.mobile {
  display: none;
}

.md-field input,
.el-input input {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.damage-button {
  background-color: var(
    --md-theme-default-primary-on-background,
    #448aff
  ) !important;
  color: white !important;
  border-radius: 32px;

  &.mobile {
    height: 64px;
  }
}

@media screen and (max-width: 740px) {
  .mobile {
    display: block;
  }

  .dashboard-view {
    margin-top: 32px;
    padding: 0 7px;
  }
}
</style>
