import {
  FETCH_NOTIFCATIONS,
  PUSH_NOTIFICATION,
  MARK_NOTIFICATIONS_AS_READ,
  MARK_NOTIFICATIONS_AS_REACTED
} from "./actions";
import { SET_NOTIFICATIONS, SET_NOTIFICATIONS_FETCHING } from "./mutations";
import apiService from "../../../services/api";

const state = {
  notifications: [],
  notificationsAreFetching: false
};

const getters = {
  notifications(state) {
    return state.notifications;
  }
};

const actions = {
  async [FETCH_NOTIFCATIONS](context) {
    if (
      !context.rootGetters.isAuthenticated ||
      !context.rootGetters.isEmailVerified
    ) {
      return context.commit(SET_NOTIFICATIONS, []);
    }
    context.commit(SET_NOTIFICATIONS_FETCHING, true);
    context.commit(SET_NOTIFICATIONS, await apiService.getNotifications());
    context.commit(SET_NOTIFICATIONS_FETCHING, false);
  },
  [PUSH_NOTIFICATION](context, payload) {
    if (
      !context.rootGetters.isAuthenticated ||
      !context.rootGetters.isEmailVerified
    ) {
      return context.commit(SET_NOTIFICATIONS, []);
    }
    context.commit(SET_NOTIFICATIONS, [
      payload,
      ...context.state.notifications
    ]);
  },
  [MARK_NOTIFICATIONS_AS_READ](context, ids) {
    if (
      !context.rootGetters.isAuthenticated ||
      !context.rootGetters.isEmailVerified
    ) {
      return context.commit(SET_NOTIFICATIONS, []);
    }
    apiService.markNotificationsAsSeen(ids);
    context.commit(
      SET_NOTIFICATIONS,
      context.state.notifications.map(n => {
        if (ids.includes(n._id)) {
          return {
            ...n,
            seenAt: new Date().toJSON
          };
        }
        return n;
      })
    );
  },
  [MARK_NOTIFICATIONS_AS_REACTED](context, ids) {
    if (
      !context.rootGetters.isAuthenticated ||
      !context.rootGetters.isEmailVerified
    ) {
      return context.commit(SET_NOTIFICATIONS, []);
    }
    apiService.markNotificationsAsReacted(ids);
    context.commit(
      SET_NOTIFICATIONS,
      context.state.notifications.map(n => {
        if (ids.includes(n._id)) {
          return {
            ...n,
            reactedAt: new Date().toJSON
          };
        }
        return n;
      })
    );
  }
};

const mutations = {
  [SET_NOTIFICATIONS_FETCHING](state, status) {
    state.notificationsAreFetching = status;
  },
  [SET_NOTIFICATIONS](state, notifications) {
    state.notifications = notifications;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
