import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import userdata from "./modules/userdata";
import damage from "./modules/damage";
import damages from "./modules/damages";
import damageList from "./modules/damage-list";
import damageListFavorites from "./modules/damage-list-favorites";
import offers from "./modules/offers";
import car from "./modules/car";
import notification from "./modules/notification";
import internal from "./modules/internal";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    userdata,
    damage,
    damages,
    damageList,
    damageListFavorites,
    offers,
    car,
    notification,
    internal
  }
});
