<template>
  <div class="wrap">
    <div class="confirm-dialog">
      <h3>{{ title }}</h3>
      <p>
        <slot />
      </p>
      <div class="btn-wrap">
        <MdButton class="md-primary" @click="$emit('close')">Weiter</MdButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OverlayConfirm",
  props: ["title"],
};
</script>

<style scoped lang="scss">
.wrap {
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  z-index: 10;

  .confirm-dialog {
    width: 400px;
    background: white;
    margin: 40px auto;
    padding: 10px 30px;
    border-radius: 16px;

    h3 {
      text-align: center;
      color: var(--md-theme-default-primary, #448aff);
    }

    p {
      font-size: 12px;
      color: gray;
    }

    .btn-wrap {
      text-align: right;
    }
  }
}

@media screen and (max-width: 740px) {
  .wrap {
    .confirm-dialog {
      width: 90%;
    }
  }
}
</style>
