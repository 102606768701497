<template>
  <div class="wrap">
    <div
      v-for="option in options"
      @click="!option.disabled && $emit('update:selected', option.key)"
      :key="option.key"
      :class="{
        item: 1,
        selected: selected === option.key,
        disabled: option.disabled
      }"
    >
      {{ option.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ClickableOptions",
  props: ["options", "selected"]
};
</script>

<style scoped lang="scss">
.wrap {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
.item {
  padding: 10px 18px;
  border: 1px solid rgb(211, 211, 211);
  border-radius: 20px;
  margin: 5px 10px 5px 0;
  cursor: pointer;
  transition: all ease 0.3s;
  font-size: 12px;
  line-height: 12px;

  &:hover {
    border: 1px solid var(--md-theme-default-primary, #448aff);
  }

  &.selected {
    color: white;
    background: var(--md-theme-default-primary, #448aff);
    border-color: transparent;
  }

  &.disabled {
    cursor: default;
    color: rgb(228, 228, 228);
    border: 1px dashed rgb(228, 228, 228);
  }
}
</style>
