<template>
  <div>
    <h2>Reparatur Typ</h2>
    <div class="content">
      <ClickableOptionBoxes
        :options="polishTypes"
        @change="change"
        :selected="polishType"
      />
    </div>
  </div>
</template>

<script>
import constants from "@/constants";
import ClickableOptionBoxes from "@/components/ClickableOptionBoxes";

export default {
  name: "PolishTypeSlide",
  data() {
    return {
      noticeOpen: true,
      polishType: null,
      polishTypes: [
        {
          key: constants.polishType.nano,
          title: "Nanoversiegelung (Deluxe)",
          description:
            "Nanoversiegelungen sind Flüssigkeiten, die Oberflächen durch den Einsatz der Nanotechnologie derart verändern, dass diese besondere Eigenschaften annehmen, z.B. Wasser abweisen, Schmutz abweisen.",
          readMoreLink: {
            text: "Mehr über Nanoversiegelung",
            url: "https://www.pkw.de/ratgeber/wartung-pflege/nanoversiegelung"
          }
        },
        {
          key: constants.polishType.wax,
          title: "Politur und wachsen (Premium)",
          description:
            "Autowachs hat eine wasserabweisende Wirkung und kann hohe Temperaturen standhalten.  Zudem ist Autowachs sehr gut geeignet um kleinere Kratzer aufzufüllen. Das Wachs dient dazu bereits polierte Oberflächen zu verbessern, und vor äußeren Einwirkungen zu schützen.",
          readMoreLink: {
            text: "Mehr über Politur und wachsen",
            url:
              "https://autopflegen.com/der-unterschied-zwischen-wachs-und-autopolitur/"
          }
        },
        {
          key: constants.polishType.polish,
          title: "Politur (Basis)",
          description:
            "Autopolitur ist extrem nützlich, wenn es um das Ausbessern kleinerer Kratzer im Lack geht. Beim Polieren wird eine winzige Schicht des Lackes „abgeschliffen“ um eine spiegelglatte Oberfläche zu erzeugen und eine gleichmäßige Lichtreflektion zu ermöglichen.",
          readMoreLink: {
            text: "Mehr über Politur",
            url:
              "https://www.allianz-autowelt.de/werkstatt/auto-polieren/#warum-auto-polieren"
          }
        }
      ]
    };
  },
  components: {
    ClickableOptionBoxes
  },
  watch: {
    polishType() {
      const { polishType } = this;
      const valid = !!polishType;

      this.$emit("change", {
        valid,
        data: {
          _polishType: polishType
        }
      });
    }
  },
  methods: {
    change(key) {
      this.polishType = key;
    }
  }
};
</script>

<style scoped lang="scss">
h2 {
  margin: 0;
  padding-bottom: 35px;
}

.content {
  position: relative;
}
</style>
