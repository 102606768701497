<template>
  <div class="wrap" :style="{ height }">
    <div ref="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Collapsable",
  props: ["collapsed"],
  data() {
    return { isMounted: false };
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    height() {
      if (!this.isMounted) {
        return "100%";
      }
      return this.collapsed ? 0 : `${this.$refs.content.offsetHeight}px`;
    }
  }
};
</script>

<style scoped lang="scss">
.wrap {
  overflow: hidden;
  transition: height ease 0.35s;
}
</style>
