<template>
  <div
    :style="{ display: visible ? null : 'none' }"
    :class="{ slide: 1, active, 'last-slide': isLastSlide }"
  >
    <slot v-if="wasRenderedOnce || preRender"></slot>
  </div>
</template>

<script>
export default {
  name: "Slide",
  props: ["active", "isLastSlide", "preRender"],
  data() {
    return {
      visible: false,
      wasRenderedOnce: false
    };
  },
  mounted() {
    this.visible = this.active;
    if (this.active) {
      this.wasRenderedOnce = true;
    }
  },
  watch: {
    active() {
      const visible = this.active;
      setTimeout(() => {
        this.visible = visible;
        if (visible) {
          this.wasRenderedOnce = true;
        }
      }, 200);
    }
  }
};
</script>

<style scoped lang="scss">
.slide {
  position: relative;
  opacity: 0;
  transform: translateX(-10%);
  transition: all ease 0.2s;
  min-height: 400px;

  &.last-slide {
    min-height: inherit;
  }

  &.active {
    opacity: 1;
    transform: translateX(0);
    transition: none;
  }
}
</style>
