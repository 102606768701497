<template>
  <div>
    <h2>Reparatur Typ</h2>
    <div class="content">
      <OverlayConfirm
        v-if="noticeOpen"
        title="Wichtig"
        @close="() => (noticeOpen = false)"
      >
        Lieber Kunde, wir möchten Sie drauf hinweisen, dass jede
        Reparaturlackierung Unterschiede zum ursprünglichen Lack aufweisen kann.
        Dies liegt an den unterschiedlich ausgeführten Techniken und verwendeten
        Lacken einer Reparaturlackierung, die sich von einer Werkslackierung
        unterscheiden. Dabei sind Farbunterschiede nahezu unvermeidbar. Unsere
        Partner-Werkstätten arbeiten mit den neuesten Lackiertechniken und sind
        auf dem aktuellen Wissensstand der Lackiermethoden.
      </OverlayConfirm>
      <ClickableOptionBoxes
        :options="repairTypes"
        @change="change"
        :selected="repairType"
      />
    </div>
  </div>
</template>

<script>
import constants from "@/constants";
import ClickableOptionBoxes from "@/components/ClickableOptionBoxes";
import OverlayConfirm from "@/components/OverlayConfirm";

export default {
  name: "RepairTypeSlide",
  data() {
    return {
      noticeOpen: true,
      repairType: null,
      repairTypes: [
        {
          key: constants.repairType.smart,
          title: "Smart-Repair",
          description:
            "Smart-Repair ist eine kostengünstige und eingeschränkte Reparaturmethode, die nicht für jede Fläche und jede Stelle des Fahrzeugs geeignet ist.",
          readMoreLink: {
            text: "Mehr über Smart-Repair (PDF)",
            url: "https://de.ppgrefinish.com/media/1002515/ifl-merkblatt_spot-lackierung.pdf",
          },
          disabled: false,
        },
        {
          key: constants.repairType.professional,
          title: "Professional",
          description:
            "Professionel ist eine Reparaturmethode die an jeder Stelle und Fläche des Fahrzeugs anzuwenden ist.",
          readMoreLink: {
            text: "Mehr über Professional (PDF)",
            url: "https://de.ppgrefinish.com/media/661416/bfl_-_merkblatt_uni_und_effektlackierung.pdf",
          },
          disabled: false,
        },
        {
          key: constants.repairType.leasing,
          title: "Leasing",
          description:
            "Leasing ist eine Reparaturmethode die nach Herstellervorgaben erfolgt.",
          readMoreLink: {
            text: "Mehr über Leasing (PDF)",
            url: "http://ifl-ev.de/download/dateien_f%C3%BCr_den_%C3%B6ffentlichen_bereich/Merkblatt-IFL-Neuauflage-2017.pdf",
          },
          disabled: true,
          note: "Bald verfügbar",
        },
      ],
    };
  },
  components: {
    ClickableOptionBoxes,
    OverlayConfirm,
  },
  watch: {
    repairType() {
      const { repairType } = this;
      const valid = !!repairType;

      this.$emit("change", {
        valid,
        data: {
          repairType,
        },
      });
    },
  },
  methods: {
    change(key) {
      this.repairType = key;
    },
  },
};
</script>

<style scoped lang="scss">
h2 {
  margin: 0;
  padding-bottom: 35px;
}

.content {
  position: relative;
}
</style>
