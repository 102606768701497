import { FETCH_CAR_MANUFACTURERS } from "./actions";
import { SET_MANUFACTURERS, SET_MANUFACTURERS_FETCHING } from "./mutations";
import apiService from "../../../services/api";

const state = {
  manufacturers: [],
  manufacturersAreFetching: false
};

const getters = {};

const actions = {
  async [FETCH_CAR_MANUFACTURERS](context) {
    if (!context.rootGetters.isAuthenticated) {
      return context.commit(SET_MANUFACTURERS, []);
    }
    context.commit(SET_MANUFACTURERS_FETCHING, true);
    context.commit(SET_MANUFACTURERS, await apiService.getCarManufacturers());
    context.commit(SET_MANUFACTURERS_FETCHING, false);
  }
};

const mutations = {
  [SET_MANUFACTURERS_FETCHING](state, status) {
    state.manufacturersAreFetching = status;
  },
  [SET_MANUFACTURERS](state, manufacturers) {
    state.manufacturers = manufacturers;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
