import Store from "../../../vuex/store";
import { PATCH_DAMAGE } from "../../../vuex/modules/damage/actions";
import {
  PATCH_DAMAGE_IN_LIST,
  REMOVE_DAMAGE_FROM_LIST
} from "../../../vuex/modules/damage-list/actions";
import {
  PATCH_DAMAGE_IN_LIST_FAVORITES,
  REMOVE_DAMAGE_FROM_LIST_FAVORITES
} from "../../../vuex/modules/damage-list-favorites/actions";

export default function(payload) {
  Store.dispatch(PATCH_DAMAGE, {
    damageId: payload.id,
    payload: { __frontend__deleted: 1 }
  });

  Store.dispatch(PATCH_DAMAGE_IN_LIST, {
    damageId: payload.id,
    payload: { __frontend__deleted: 1 }
  });
  setTimeout(() => {
    Store.dispatch(REMOVE_DAMAGE_FROM_LIST, {
      damageId: payload.id
    });
  }, 10000);

  Store.dispatch(PATCH_DAMAGE_IN_LIST_FAVORITES, {
    damageId: payload.id,
    payload: { __frontend__deleted: 1 }
  });
  setTimeout(() => {
    Store.dispatch(REMOVE_DAMAGE_FROM_LIST_FAVORITES, {
      damageId: payload.id,
      onlyFrontend: true
    });
  }, 10000);
}
