<template>
  <header>
    <div class="flex-wrap">
      <div class="left-side">
        <div @click="$emit('brandclick')" class="brand">
          <img src="../assets/logo.svg" alt="logo" />
        </div>
        <div @click="$emit('test-phase-click')" class="test-phase">
          <md-icon>science</md-icon>
          <span>Testphase</span>
        </div>
      </div>
      <div>
        <div class="header-buttons">
          <md-button
            @click="$emit('adddamageclick')"
            class="md-primary damage-button desktop"
            v-if="!isGarageAccount"
          >
            Schaden hinzufügen
          </md-button>

          <md-menu md-align-trigger>
            <md-button md-menu-trigger class="md-icon-button help-button">
              <md-icon>help_outline</md-icon>
            </md-button>
            <md-menu-content>
              <md-menu-item href="mailto:support@bynand.de"
                >Support (E-Mail)</md-menu-item
              >
            </md-menu-content>
          </md-menu>

          <Notifications />
          <md-menu md-align-trigger>
            <md-button md-menu-trigger class="md-icon-button user-button">
              <md-icon>person</md-icon>
            </md-button>
            <md-menu-content>
              <md-menu-item @click="$emit('myaccountclick')"
                >Mein Account</md-menu-item
              >
              <md-menu-item @click="$emit('logoutclick')">Logout</md-menu-item>
            </md-menu-content>
          </md-menu>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import constants from "@/constants";
import Notifications from "./Notifications";

export default {
  name: "AppHeader",
  computed: {
    ...mapGetters(["isAuthenticated", "userData"]),
    isGarageAccount() {
      return this.userData.accountType === constants.accountType.garage;
    },
  },
  components: {
    Notifications,
  },
};
</script>

<style scoped lang="scss">
header {
  height: 54px;
  position: relative;
  padding: 7px 35px;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.473);
  background: white;
  z-index: 1;

  .flex-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.left-side {
  display: flex;
  justify-content: flex-start;
}

.brand {
  align-items: center;
  cursor: pointer;
  text-align: left;
  width: 32px;
  height: 32px;

  img {
    height: 100%;
  }
}

.test-phase {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
  padding: 2px 10px;
  border-radius: 25px;
  font-size: 12px;
  border: 2px solid #ff6666;
  font-weight: bold;
  color: #ff6666;
  i.md-icon.md-theme-default.md-icon-font {
    margin: 0 6px 0 0;
    color: #ff6666;
  }
}

.user-button {
  margin-left: 10px;
  background: rgba(0, 0, 0, 0.54);

  i.md-icon.md-theme-default.md-icon-font {
    color: rgba(255, 255, 255, 0.89);
  }
}

.help-button {
  i.md-icon.md-theme-default.md-icon-font {
    color: rgba(0, 0, 0, 0.3);
  }
}

.damage-button {
  margin: 0 50px 0 0;
}

.header-buttons {
  display: flex;
  align-items: center;
}

.mobile {
  display: none;
}

@media screen and (max-width: 740px) {
  .desktop {
    display: none;
  }
}
</style>
