<template>
  <div @click="click">
    <slot></slot>
    <input
      class="hidden"
      ref="input"
      type="file"
      :accept="accept"
      @change="change"
    />
  </div>
</template>

<script>
import heic2any from "heic2any";
export default {
  name: "UploadContainer",
  props: ["accept"],
  methods: {
    click() {
      this.$refs.input.click();
    },

    async convertingUnsupportedImages(e) {
      let files = [...e.target.files];
      files = await Promise.all(
        files.map(async file => {
          if (
            file.type.toLowerCase() == "image/heic" ||
            file.type.toLowerCase() == "image.heif"
          ) {
            file = await heic2any({
              blob: file,
              toType: "image/jpeg"
            });
          }
          return file;
        })
      );
      return files;
    },

    async change(e) {
      this.$emit("converting", true);
      const files = await this.convertingUnsupportedImages(e);
      this.$emit("converting", false);
      this.$emit("change", { e, files });
    }
  }
};
</script>

<style scoped lang="scss">
.hidden {
  visibility: hidden;
}
</style>
