import errors from "./errors";
import damageType from "./damageType";
import accountType from "./accountType";
import repairType from "./repairType";
import polishType from "./polishType";
import requestType from "./requestType";
import socketEvents from "./socketEvents";
import offerType from "./offerType";
import notificationType from "./notificationType";
import offerStatus from "./offerStatus";
import repairStatus from "./repairStatus";
import offerInvalidationReason from "./offerInvalidationReason";

export default {
  errors,
  damageType,
  accountType,
  repairType,
  polishType,
  requestType,
  socketEvents,
  offerType,
  notificationType,
  offerStatus,
  repairStatus,
  offerInvalidationReason
};
