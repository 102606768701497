import { render, staticRenderFns } from "./ClickableOptions.vue?vue&type=template&id=b85f1f08&scoped=true"
import script from "./ClickableOptions.vue?vue&type=script&lang=js"
export * from "./ClickableOptions.vue?vue&type=script&lang=js"
import style0 from "./ClickableOptions.vue?vue&type=style&index=0&id=b85f1f08&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_prettier@3.1.1_vue-template-compiler@2.7.16_webpack@5.90.3/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b85f1f08",
  null
  
)

export default component.exports