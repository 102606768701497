<template>
  <div>
    <div class="container">
      <h2>Welches Auto hat den Schaden?</h2>

      <div class="spaced">
        <el-autocomplete
          class="input-with-select"
          v-model="carModel"
          :fetch-suggestions="querySearchModel"
          :placeholder="
            !carManufacturer ? 'Wähle zuerst den Hersteller aus.' : 'Modell'
          "
          @select="handleModelSelect"
        >
          <el-select
            class="manufacturer"
            slot="prepend"
            v-model="carManufacturer"
            placeholder="Hersteller"
          >
            <el-option
              v-for="manufacturer in manufacturersNames"
              :value="manufacturer"
              :key="manufacturer"
              :label="manufacturer"
            ></el-option>
          </el-select>
          <el-select
            class="year"
            slot="append"
            v-model="carYear"
            placeholder="EZ"
            title="Erstzulassung"
          >
            <el-option
              v-for="year in years"
              :value="year"
              :key="year"
              :label="year"
            ></el-option>
          </el-select>
        </el-autocomplete>
      </div>

      <div class="input-flex-wrap">
        <md-field>
          <label for="movie">HSN</label>
          <md-input v-model="carHsn" maxlength="4"></md-input>
          <span class="md-helper-text">2.1 im Fahrzeugschein</span>
        </md-field>
        <span></span>
        <md-field>
          <label for="movie">TSN</label>
          <md-input v-model="carTsn" maxlength="3"></md-input>
          <span class="md-helper-text">2.2 im Fahrzeugschein</span>
        </md-field>
      </div>

      <div class="input-flex-wrap">
        <md-field>
          <label for="movie">Fahrzeug-Ident.-Nr,</label>
          <md-input v-model="carFin" maxlength="17"></md-input>
          <span class="md-helper-text">4 im Fahrzeugschein</span>
        </md-field>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import { mapState } from "vuex";
import { FETCH_CAR_MANUFACTURERS } from "@/vuex/modules/car/actions";

const isset = (obj, attr) => {
  return Object.keys(obj).includes(attr) && obj[attr] !== undefined;
};

const getYearRange = (from, to) => {
  let fullRange = [...Array(100).keys()]
    .map(n => new Date().getFullYear() - n)
    .reverse();

  if (from) {
    const fromInt = parseInt(from);
    while (fullRange[0] < fromInt) {
      fullRange.shift();
    }
  }

  if (to) {
    const toInt = parseInt(to);
    while (fullRange[fullRange.length - 1] > toInt) {
      fullRange.pop();
    }
  }

  return fullRange.reverse();
};

export default {
  name: "CarDetailsSlide",
  created() {
    this.$store.dispatch(FETCH_CAR_MANUFACTURERS);
  },
  data() {
    return {
      carManufacturer: "",
      carModel: "",
      carYear: "",
      carHsn: "",
      carTsn: "",
      carFin: "",
      models: [],
      modelsNames: [],
      modelsLoading: false,
      years: getYearRange()
    };
  },
  computed: {
    ...mapState({
      manufacturers: state => state.car.manufacturers,
      manufacturersAreFetching: state => state.car.manufacturersAreFetching
    }),
    manufacturersNames() {
      return this.manufacturers.map(m => m.name);
    },
    data() {
      return {
        carManufacturer: this.carManufacturer,
        carModel: this.carModel,
        carYear: this.carYear,
        carHsn: this.carHsn || undefined,
        carTsn: this.carTsn || undefined,
        carFin: this.carFin || undefined
      };
    }
  },
  watch: {
    async carManufacturer() {
      this.carModel = "";
      if (this.carManufacturer) {
        this.modelsLoading = true;
        this.carHsn = this.manufacturers.find(
          m => m.name === this.carManufacturer
        ).hsn;
        this.models = await api.getCarModels(this.carManufacturer);
        this.modelsNames = (this.models || []).map(m => m.model);
        this.modelsLoading = false;
      }
    },
    async data() {
      this.validateData();
    }
  },
  methods: {
    querySearchModel(queryString, cb) {
      var results = !queryString
        ? this.modelsNames
        : this.modelsNames.filter(
            model =>
              model.toLowerCase().replace(queryString.toLowerCase(), "")
                .length != model.toLowerCase().length
          );
      cb(results.map(r => ({ value: r })));
    },
    handleModelSelect({ value }) {
      const model = this.models.find(m => m.model === value);
      this.carModel = value;

      const yearRange = getYearRange(model.year_start, model.year_end);
      this.years = yearRange;
      this.carYear = "";
    },
    validateData() {
      const { data } = this;

      const valid =
        (!isset(data, "carHsn") || data.carHsn.length == 4) &&
        (!isset(data, "carTsn") || data.carTsn.length == 3) &&
        (!isset(data, "carFin") || data.carFin.length == 17) &&
        this.manufacturers.find(m => m.name === data.carManufacturer) &&
        this.modelsNames.includes(data.carModel) &&
        this.years.includes(data.carYear);

      this.$emit("change", {
        valid,
        data
      });
    }
  }
};
</script>

<style scoped lang="scss">
h2 {
  margin: 0;
  padding-bottom: 15px;
}
h3 {
  font-weight: normal;
  color: gray;
  margin: 16px 0 8px 0;
}
.container {
  padding-top: 10px;
  padding-bottom: 25px;
}
.plz-field {
  width: 200px;
  text-align: right;
  padding-right: 5px;
}
.spaced {
  margin: 40px 0 25px 0;
}
.small-input {
  width: calc(50% - 10px);
}
.input-flex-wrap {
  display: flex;
  margin-top: 5px;

  & > span {
    width: 30px;
  }
}
</style>

<style lang="scss">
.input-with-select {
  width: 100%;

  .el-select.manufacturer .el-input {
    width: 160px;
  }

  .el-select.year .el-input {
    width: 80px;
  }
}
</style>
