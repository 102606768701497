<template>
  <div :style="{ height: `${height}px`, width: `${width}px` }" class="blurhash">
    <canvas ref="canvas" width="32" height="32"></canvas>
  </div>
</template>

<script>
import { decode } from "blurhash";

export default {
  name: "Blurhash",
  props: ["src", "height", "width"],
  mounted() {
    this.render();
  },
  methods: {
    async render() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");

      const pixels = decode(this.src, 32, 32);

      const imageData = new ImageData(pixels, 32, 32);
      ctx.putImageData(imageData, 0, 0);
    }
  }
};
</script>

<style scoped lang="scss">
.blurhash {
  position: relative;

  canvas {
    height: 100%;
    width: 100%;
    background: #ddd;
  }
}
</style>
