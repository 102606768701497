<template>
  <div class="wrap">
    <div class="notice">
      <h2>Zeit für die Fotos</h2>
      Wir benötigen jetzt noch ein paar Fotos von deinem Schaden.<br />
      Bitte beachte die folgenden Tipps für gute Fotos:

      <ul>
        <li>
          <div class="icon"><md-icon>check</md-icon></div>
          <div class="text">Die Schadenstelle sollte gesäubert sein</div>
        </li>
        <li>
          <div class="icon"><md-icon>check</md-icon></div>
          <div class="text">
            Der Schaden sollte gut erkennbar sein
            <div class="subtext" v-if="isDent">
              Du kannst einen
              <a
                href="https://ihr-gutachten.com/download/dellenreflektor.pdf"
                target="_blank"
                >Dellenreflektor</a
              >
              drucken und zur Hilfe nehmen.
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import constants from "@/constants";
export default {
  name: "PhotosTippsSlide",
  props: ["damageType"],
  computed: {
    isDent() {
      return this.damageType === constants.damageType.dent;
    }
  },
  mounted() {
    this.$emit("change", {
      valid: true,
      data: {}
    });
  }
};
</script>

<style scoped lang="scss">
.wrap {
  padding-top: 35px;
}
.notice {
  padding: 30px;
  border: 1px solid rgb(223, 223, 223);
  border-radius: 6px;
  font-size: 14px;

  h2 {
    margin: 0 0 15px 0;
  }
}

ul {
  list-style: none;
  margin: 50px 0 20px 0;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    margin: 30px 0;

    .icon {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin: 0 10px 0 0;
      border-radius: 50%;
      background: var(--md-theme-default-primary-on-background, #448aff);
      i {
        color: white !important;
      }
    }

    .text {
      font-weight: bold;
      font-size: 16px;

      .subtext {
        font-size: 12px;
        color: rgb(165, 165, 165);
      }
    }
  }
}
</style>
