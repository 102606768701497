export default {
  apiUrl:
    "https://maps.googleapis.com/maps/api/js?key=<key>&libraries=<libraries>",

  inject(apiKey, libraries = ["places"]) {
    const script = window.document.createElement("script");
    script.setAttribute("src", this.generateSrc(apiKey, libraries));
    window.document.head.appendChild(script);
  },

  generateSrc(apiKey, libraries) {
    return this.apiUrl
      .replace("<key>", apiKey)
      .replace("<libraries>", libraries.join(","));
  }
};
