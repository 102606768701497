import Store from "../../../vuex/store";
import { PATCH_DAMAGE } from "../../../vuex/modules/damage/actions";
import { PATCH_DAMAGES_DAMAGE } from "../../../vuex/modules/damages/actions";

export default function(payload) {
  Store.dispatch(PATCH_DAMAGE, {
    damageId: payload.id,
    payload: payload.data
  });
  Store.dispatch(PATCH_DAMAGES_DAMAGE, {
    damageId: payload.id,
    payload: payload.data
  });
}
