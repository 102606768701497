import { FETCH_OFFERS, PATCH_OFFER, REMOVE_OFFER } from "./actions";
import { SET_OFFERS, SET_OFFERS_FETCHING, SET_OFFERS_ERROR } from "./mutations";
import apiService from "../../../services/api";
import offerStatus from "../../../constants/offerStatus";
import merge from "deepmerge";

const state = {
  error: null,
  isFetching: false,
  offers: []
};

const getters = {
  invalidatedOffers: state => {
    return state.offers.filter(
      offer => offer.status === offerStatus.invalidated
    );
  },
  openOffers: state => {
    return state.offers.filter(offer => offer.status === offerStatus.open);
  },
  acceptedOffers: state => {
    return state.offers.filter(offer => offer.status === offerStatus.accepted);
  },
  doneOffers: state => {
    return state.offers.filter(offer => offer.status === offerStatus.done);
  }
};

const actions = {
  async [FETCH_OFFERS](context) {
    if (!context.rootGetters.isAuthenticated) {
      return context.commit(SET_OFFERS, []);
    }
    if (context.state.isFetching) {
      return;
    }
    context.commit(SET_OFFERS_ERROR, null);
    context.commit(SET_OFFERS_FETCHING, true);
    let offers;
    try {
      offers = await apiService.getOffers();
    } catch (error) {
      offers = [];
      context.commit(SET_OFFERS_ERROR, error.response.data);
    }
    context.commit(SET_OFFERS, offers);
    context.commit(SET_OFFERS_FETCHING, false);
  },
  async [PATCH_OFFER](context, { offerId, payload }) {
    if (!context.rootGetters.isAuthenticated) {
      return context.commit(SET_OFFERS, []);
    }
    context.commit(
      SET_OFFERS,
      context.state.offers.map(offer => {
        if (offer._id === offerId) {
          return merge(offer, payload, { arrayMerge: (_, src) => src });
        }
        return offer;
      })
    );
  },
  async [REMOVE_OFFER](context, { offerId }) {
    if (!context.rootGetters.isAuthenticated) {
      return context.commit(SET_OFFERS, []);
    }
    context.commit(
      SET_OFFERS,
      context.state.offers.filter(offer => offer._id !== offerId)
    );
  }
};

const mutations = {
  [SET_OFFERS_ERROR](state, error) {
    state.error = error;
  },
  [SET_OFFERS_FETCHING](state, status) {
    if (status) {
      state.error = null;
    }
    state.isFetching = status;
  },
  [SET_OFFERS](state, offers) {
    state.offers = offers;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
