import { FETCH_USER, UPDATE_USER } from "./actions";
import { SET_USER, SET_ERROR, SET_FETCHING, SET_UPDATING } from "./mutations";
import apiService from "../../../services/api";

const state = {
  isFetching: false,
  isUpdating: false,
  data: null,
  error: null
};

const getters = {
  userData(state) {
    return state.data || {};
  },
  userDataIsFetching(state) {
    return state.isFetching;
  },
  userDataIsUpdating(state) {
    return state.isUpdating;
  }
};

const actions = {
  async [UPDATE_USER](context, userData) {
    context.commit(SET_UPDATING, true);
    context.commit(SET_USER, await apiService.updateUser(userData));
    context.commit(SET_UPDATING, false);
  },
  async [FETCH_USER](context) {
    if (
      !context.rootGetters.isAuthenticated ||
      !context.rootGetters.isEmailVerified
    ) {
      return context.commit(SET_USER, null);
    }
    context.commit(SET_FETCHING, true);
    context.commit(SET_ERROR, null);
    try {
      context.commit(SET_USER, await apiService.getUser());
    } catch (error) {
      context.commit(SET_ERROR, error);
    }
    context.commit(SET_FETCHING, false);
  }
};

const mutations = {
  [SET_UPDATING](state, status) {
    state.isUpdating = status;
  },
  [SET_FETCHING](state, status) {
    state.isFetching = status;
  },
  [SET_ERROR](state, error) {
    state.error = error;
  },
  [SET_USER](state, user) {
    state.data = {
      ...user,
      birthdate: user && user.birthdate && new Date(user.birthdate)
    };
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
