import axios from "axios";
import axiosRetry from "axios-retry";
import * as Sentry from "@sentry/browser";

const apiService = {
  url: process.env.VUE_APP_API_URL,
  _axios: null,
  authToken: null,

  init() {
    this.updateAxiosInstance();
  },

  async axios() {
    await this._updateAxiosInstance();
    return this._axios;
  },

  updateAxiosInstance(user, forceRefresh = false) {
    this.user = user;
    return this._updateAxiosInstance(forceRefresh);
  },

  async _updateAxiosInstance(forceRefresh = false) {
    const newToken = this.user && (await this.user.getIdToken(forceRefresh));
    if (this.authToken === newToken) {
      return;
    }
    this.authToken = newToken;
    this._axios = axios.create({
      baseURL: this.url,
      timeout: 60000,
      headers: this.authToken
        ? { authorization: `Bearer ${this.authToken}` }
        : undefined
    });
    axiosRetry(this._axios, { retries: 0 });
  },

  _errorVersionOfData(data) {
    if (!data) return null;
    try {
      data = JSON.parse(data);
      if (data.images) data.images = "[IMAGES]";
      console.log(data);
      return data;
    } catch (error) {
      return data;
    }
  },

  _errorLoggerWrap(axiosPromise) {
    return axiosPromise.catch(err => {
      console.error(err);
      const { config } = err;
      const data = this._errorVersionOfData(config.data);
      let errSentry = new Error(err);
      delete errSentry.config;
      Sentry.withScope(function(scope) {
        scope.setExtra("url", config.url);
        scope.setExtra("method", config.method);
        scope.setExtra("headers", config.headers);
        scope.setExtra("data", data);
        scope.setExtra(
          "response(stringified)",
          JSON.stringify(err.response.data)
        );
        Sentry.captureException(errSentry);
      });
      throw err;
    });
  },

  async checkInviteCode(email, code) {
    const axios = await this.axios();
    return (
      await this._errorLoggerWrap(
        axios.post("user/check-invite-code", { email, code })
      )
    ).data;
  },

  async register(email, code, firstname, lastname, password) {
    const axios = await this.axios();
    return (
      await axios.post("user/register", {
        email,
        code,
        firstname,
        lastname,
        password
      })
    ).data;
  },

  async registerCustomer(email, firstname, lastname, password) {
    const axios = await this.axios();
    return (
      await axios.post("user/register/customer", {
        email,
        firstname,
        lastname,
        password
      })
    ).data;
  },

  async getUser() {
    const axios = await this.axios();
    return (await this._errorLoggerWrap(axios.get("user"))).data;
  },

  async getNotifications({ limit, lastId } = {}) {
    const axios = await this.axios();
    const params = {
      limit: limit || undefined,
      lastId: lastId || undefined
    };
    return (
      await this._errorLoggerWrap(axios.get("user/notifications", { params }))
    ).data;
  },

  async markNotificationsAsSeen(ids) {
    const axios = await this.axios();
    return (
      await this._errorLoggerWrap(
        axios.post("user/notifications/mark-seen", { ids })
      )
    ).data;
  },

  async markNotificationsAsReacted(ids) {
    const axios = await this.axios();
    return (
      await this._errorLoggerWrap(
        axios.post("user/notifications/mark-reacted", { ids })
      )
    ).data;
  },

  async updateUser(userData) {
    const axios = await this.axios();
    return (await this._errorLoggerWrap(axios.post("user", userData))).data;
  },

  async getDamages() {
    const axios = await this.axios();
    return (await this._errorLoggerWrap(axios.get("damages"))).data;
  },

  async getDamage(id) {
    const axios = await this.axios();
    return (await this._errorLoggerWrap(axios.get(`damages/${id}`))).data;
  },

  async deleteDamage(id) {
    const axios = await this.axios();
    return (await this._errorLoggerWrap(axios.delete(`damages/${id}`))).data;
  },

  async addDamage(payload) {
    const axios = await this.axios();
    return (await this._errorLoggerWrap(axios.post("damages", payload))).data;
  },

  async addDamageImage(id, image) {
    const formData = new FormData();
    formData.append("image", image);
    const axios = await this.axios();
    return (
      await this._errorLoggerWrap(
        axios.post(`damages/${id}/image`, formData, {
          timeout: 15000,
          "axios-retry": {
            retries: 3
          }
        })
      )
    ).data;
  },

  async addDamageVisit(id) {
    const axios = await this.axios();
    return (await this._errorLoggerWrap(axios.post(`damages/${id}/visit`)))
      .data;
  },

  async getDamageListFavorites() {
    const axios = await this.axios();
    return (await this._errorLoggerWrap(axios.get(`damages/list/favorites`)))
      .data;
  },

  async addDamageListFavorite(damageId) {
    const axios = await this.axios();
    return (
      await this._errorLoggerWrap(
        axios.post(`damages/list/favorites`, { damageId })
      )
    ).data;
  },

  async removeDamageListFavorite(damageId) {
    const axios = await this.axios();
    return (
      await axios.delete(`damages/list/favorites`, { data: { damageId } })
    ).data;
  },

  async getDamageList({ sort, filter, lastId, limit } = {}) {
    const axios = await this.axios();
    const params = {
      sort: sort || undefined,
      filter: filter || undefined,
      lastId: lastId || undefined,
      limit: limit || undefined
    };
    return (await this._errorLoggerWrap(axios.get("damages/list", { params })))
      .data;
  },

  async getOffers() {
    const axios = await this.axios();
    return (await this._errorLoggerWrap(axios.get(`/offers`))).data;
  },

  async addOffer(damageId, payload) {
    const axios = await this.axios();
    return (
      await this._errorLoggerWrap(
        axios.post(`damages/${damageId}/offers`, payload)
      )
    ).data;
  },

  async deleteOffer(offerId) {
    const axios = await this.axios();
    return (await this._errorLoggerWrap(axios.delete(`offers/${offerId}`)))
      .data;
  },

  async reactivateOffer(offerId) {
    const axios = await this.axios();
    return (
      await this._errorLoggerWrap(axios.post(`offers/${offerId}/reactivate`))
    ).data;
  },

  async patchOffer(offerId, payload) {
    const axios = await this.axios();
    return (
      await this._errorLoggerWrap(axios.patch(`offers/${offerId}`, payload))
    ).data;
  },

  async acceptOffer(damageId, payload) {
    const axios = await this.axios();
    return (
      await this._errorLoggerWrap(
        axios.post(`damages/${damageId}/accept-offer`, payload)
      )
    ).data;
  },

  async updatePrice(damageId, payload) {
    const axios = await this.axios();
    return (
      await this._errorLoggerWrap(
        axios.post(`damages/${damageId}/update-price`, payload)
      )
    ).data;
  },

  async updateRepairStatus(damageId, payload) {
    const axios = await this.axios();
    return (
      await this._errorLoggerWrap(
        axios.post(`damages/${damageId}/update-repair-status`, payload)
      )
    ).data;
  },

  async getCarManufacturers() {
    const axios = await this.axios();
    return (await this._errorLoggerWrap(axios.get("car/manufacturers"))).data;
  },

  async getCarModels(manufacturer) {
    const axios = await this.axios();
    return (
      await this._errorLoggerWrap(
        axios.get("car/models", { params: { manufacturer } })
      )
    ).data;
  },

  async getPaypalClientId() {
    const axios = await this.axios();
    return (await this._errorLoggerWrap(axios.get("payment/clientid"))).data;
  },

  async getUploadKey() {
    const axios = await this.axios();
    return (await this._errorLoggerWrap(axios.post(`upload`))).data;
  },

  async getUploadInfo(key) {
    const axios = await this.axios();
    return (await this._errorLoggerWrap(axios.get(`upload/${key}`))).data;
  },

  async uploadImagePreview(key, index, blurhash) {
    const axios = await this.axios();
    return (
      await this._errorLoggerWrap(
        axios.post(
          `upload/${key}/${index}/preview`,
          { blurhash },
          {
            timeout: 15000,
            "axios-retry": {
              retries: 3
            }
          }
        )
      )
    ).data;
  },

  async uploadImage(key, index, name, type, image, blurhash) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("type", type);
    formData.append("image", image);
    formData.append("blurhash", blurhash);
    const axios = await this.axios();
    return (
      await this._errorLoggerWrap(
        axios.post(`upload/${key}/${index}`, formData, {
          timeout: 15000,
          "axios-retry": {
            retries: 3
          }
        })
      )
    ).data;
  }
};

export default apiService;
