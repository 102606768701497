import Vue from "vue";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_URL,
  environment: process.env.VUE_APP_SENTRY_ENV,
  integrations: [
    new Integrations.ExtraErrorData({ depth: 10 }),
    new Integrations.Vue({ Vue, attachProps: true }),
  ],
});

import VueGtag from "vue-gtag";
import * as firebase from "firebase/app";
import * as VueGoogleMaps from "vue2-google-maps";
import "firebase/compat/auth";
import {
  MdButton,
  MdDialog,
  MdField,
  MdAvatar,
  MdMenu,
  MdSwitch,
  MdList,
  MdDatepicker,
  MdProgress,
  MdCheckbox,
  MdSteppers,
  MdTable,
  MdAutocomplete,
  MdEmptyState,
  MdDialogConfirm,
  MdDialogPrompt,
  MdDialogAlert,
  MdTooltip,
} from "vue-material/dist/components";
import {
  Autocomplete,
  Select,
  Option,
  Input,
  Dialog,
  Tooltip,
  Collapse,
  CollapseItem,
} from "element-ui";

import lang from "element-ui/lib/locale/lang/de";
import locale from "element-ui/lib/locale";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import Vuebar from "vuebar";
import moment from "moment";
import VueMoment from "vue-moment";
import FunctionalCalendar from "vue-functional-calendar";
import Message from "vue-m-message";
import "vue-m-message/dist/index.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCoins,
  faCreditCard,
  faStream,
  faCheck,
  faCheckDouble,
  faCarCrash,
  faPaperPlane,
  faArrowRight,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { faCcPaypal } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import App from "./App.vue";
import router from "./router";
import store from "./vuex/store";

import auth from "./services/auth";
import api from "./services/api";
import files from "./services/files";
import maps from "./services/maps";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false;
moment.locale("de");

// element-ui locale
locale.use(lang);

library.add(
  faCarCrash,
  faCoins,
  faCcPaypal,
  faCreditCard,
  faStream,
  faCheck,
  faCheckDouble,
  faPaperPlane,
  faArrowRight,
  faStar
);
Vue.component("FontAwesomeIcon", FontAwesomeIcon);

Vue.use(VueMoment, {
  moment,
});

Vue.use(Vuebar);
Vue.use(MdButton);
Vue.use(MdDialog);
Vue.use(MdField);
Vue.use(MdAvatar);
Vue.use(MdList);
Vue.use(MdMenu);
Vue.use(MdSwitch);
Vue.use(MdProgress);
Vue.use(MdDatepicker);
Vue.use(MdCheckbox);
Vue.use(MdSteppers);
Vue.use(MdTable);
Vue.use(MdAutocomplete);
Vue.use(MdEmptyState);
Vue.use(MdDialogConfirm);
Vue.use(MdDialogPrompt);
Vue.use(MdDialogAlert);
Vue.use(MdTooltip);

Vue.use(Autocomplete);
Vue.use(Select);
Vue.use(Option);
Vue.use(Input);
Vue.use(Dialog);
Vue.use(Tooltip);
Vue.use(Collapse);
Vue.use(CollapseItem);

Vue.use(Message, { name: "msg" });
Vue.use(FunctionalCalendar, {
  dayNames: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
  monthNames: [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "July",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ],
  shortMonthNames: [
    "Jan",
    "Feb",
    "Mär",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dez",
  ],
});

auth.init(store);
api.init(store);
files.init(store);

// todo: atm google maps gets included twice, probably find some solution in the futur...
// we could let the maps service just use the google maps object from VueGoogleMaps
maps.inject(process.env.VUE_APP_MAPS_API_KEY);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAPS_API_KEY,
    // libraries: 'places',
  },
  installComponents: true,
});

if (process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
  Vue.use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID },
      appName: "Bynand WebApp",
      pageTrackerScreenviewEnabled: true,
    },
    router
  );
} else {
  console.warn("Not using google analytics.");
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
