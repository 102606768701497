<template>
  <div :class="{ wrap: 1, error: !!error }">
    <div :class="{ loading: 1, visible: loading }">
      <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
    </div>
    <div class="icon-wrap">
      <md-icon class="md-size-2x icon">{{
        error ? "priority_high" : "priority_high"
      }}</md-icon>
    </div>

    <h2 v-if="!error">Glückwunsch!</h2>
    <h2 v-else>Unbekannter Fehler</h2>

    <h3 v-if="!error">Dein Angebot wurde erfolgreich eingestellt</h3>
    <h3 v-else>
      Dein Schaden konnte leider nicht erstellt werden.<br />Überprüfe deine
      Angaben oder melde dich bei uns für support.
    </h3>

    <button v-if="!error" class="button" @click="$emit('go-to-details')">
      Weiter zum Angebot
    </button>
    <button v-else class="button" @click="$emit('go-back')">
      Angaben überprüfen
    </button>
  </div>
</template>

<script>
export default {
  name: "ThankYouSlide",
  props: ["loading", "error"]
};
</script>

<style scoped lang="scss">
.wrap {
  position: relative;
  background-color: #a4e5d1;
  height: 100%;
  padding: 60px 0 30px 0;
  text-align: center;
  border-radius: 12px;
  margin: 4px;

  &.error {
    background-color: #e98f95;

    .icon-wrap {
      background-color: #e94650;
    }

    .icon {
      color: #e98f95 !important;
    }

    .button {
      background-color: #e94650;

      &:hover {
        background-color: #af313a;
      }
    }
  }

  .loading {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: white;
    padding-top: 180px;

    &.visible {
      display: block;
    }
  }

  .icon-wrap {
    width: 80px;
    height: 80px;
    display: flex;
    margin: 0 auto 40px auto;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #6ab6a3;
  }

  .icon {
    color: #a4e5d1 !important;
  }

  .button {
    border: none;
    cursor: pointer;
    background-color: #6ab6a3;
    color: white;
    font-size: 14px;
    padding: 13px 80px;
    border-radius: 30px;
    width: auto;
    white-space: nowrap;
    &:hover {
      background-color: #85cfbd;
    }
  }

  h2 {
    margin: 0;
    text-align: center;
    color: #324b53;
  }

  h3 {
    margin: 10px 0 70px 0;
    text-align: center;
    font-weight: lighter;
    color: #324b53;
  }
}
</style>
