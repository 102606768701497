<template>
  <el-dialog
    :visible="open"
    @close="close"
    :close-on-click-modal="false"
    :close-on-press-escape="!loading"
    :destroy-on-close="true"
    custom-class="add-damage-dialog"
    ref="container"
  >
    <ProgressLoader v-if="progress !== 100" :progress="progress" />
    <div
      :class="{
        'add-damage-dialog-slides': 1,
        'last-slide': progress === 100,
        'height-animate-active': heightAnimation,
      }"
      :style="heightStyle"
    >
      <div ref="content">
        <Slide :active="step === 1">
          <LocationDetails @change="change(1, $event)" />
        </Slide>
        <Slide :preRender="true" :active="step === 2">
          <CarDetails @change="change(2, $event)" />
        </Slide>
        <Slide :active="step === 3">
          <DamageType @change="change(3, $event)" />
        </Slide>
        <Slide :active="step === 4">
          <DamageLocation
            :damageType="damageType"
            @change="change(4, $event)"
          />
        </Slide>

        <Slide :active="step === 5">
          <PhotoTipps :damageType="damageType" @change="change(5, $event)" />
        </Slide>

        <Slide :active="step === 6">
          <Photos
            @change="change(6, $event)"
            :uploadKey="photoUploadKey"
            :damageType="damageType"
          />
        </Slide>

        <Slide v-if="isPolish" :active="step === 7">
          <PolishType @change="change(7, $event)" />
        </Slide>
        <Slide v-else :active="step === 7">
          <RepairType @change="change(7, $event)" />
        </Slide>

        <!-- <Slide :active="step === 8">
          <RequestType @change="change(8, $event)" />
        </Slide> -->
        <Slide :isLastSlide="true" :active="progress === 100">
          <ThankYou
            :loading="loading"
            :error="error"
            @go-to-details="goToDetails"
            @go-back="prevSlide"
          />
        </Slide>

        <div v-if="progress !== 100" class="button-wrap">
          <MdButton :disabled="step === 1" class="md-primary" @click="prevSlide"
            >Zurück</MdButton
          >
          <MdButton
            class="md-primary md-raised"
            @click="nextSlide"
            :disabled="!canContinue"
            >{{ step === stepsCount ? "Erstellen" : "Weiter" }}</MdButton
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import constants from "@/constants";
import api from "@/services/api";
import { FETCH_DAMAGES } from "@/vuex/modules/damages/actions";
import Slide from "./Slide.vue";
import ProgressLoader from "./ProgressLoader.vue";
import DamageType from "./Slides/DamageType";
import PolishType from "./Slides/PolishType";
import DamageLocation from "./Slides/DamageLocation";
import CarDetails from "./Slides/CarDetails";
import LocationDetails from "./Slides/LocationDetails";
import PhotoTipps from "./Slides/PhotoTipps";
import Photos from "./Slides/Photos";
import RepairType from "./Slides/RepairType";
// import RequestType from "./Slides/RequestType";
import ThankYou from "./Slides/ThankYou";

export default {
  name: "AddDamageDialog",
  props: ["open"],
  data() {
    return {
      createdDamageId: null,
      step: 1,
      stepsCount: 7,
      error: null,
      loading: false,
      heightAnimation: false,
      heightStyle: {},
      data: {},
      valid: {},
      photoUploadKey: null,
    };
  },
  components: {
    Slide,
    ProgressLoader,
    DamageType,
    CarDetails,
    DamageLocation,
    LocationDetails,
    PhotoTipps,
    Photos,
    RepairType,
    PolishType,
    // RequestType,
    ThankYou,
  },
  computed: {
    progress() {
      return (100 * (this.step - 1)) / this.stepsCount;
    },
    canContinue() {
      return this.valid[this.step];
    },
    damageType() {
      return this.data.damages && this.data.damages.length
        ? this.data.damages.map((x) => x.damageType).join(";")
        : "";
    },
    isPolish() {
      return this.damageType === constants.damageType.polish;
    },
  },
  methods: {
    onSlideOpen(slide) {
      if (slide == 6) {
        if (!this.photoUploadKey) this.fetchPhotoUploadKey();
      }

      if (this.progress === 100) {
        this.addDamage();
      }
    },
    shouldSkipSlide(slide) {
      if (slide === 4) {
        // DamageLocation
        const damageLocationskipDamageTypes = [constants.damageType.polish];
        if (damageLocationskipDamageTypes.includes(this.damageType)) {
          return true;
        }
      }
      return false;
    },
    goToDetails() {
      const { createdDamageId } = this;
      const url = `/damage/${createdDamageId}`;
      this.$emit("close");
      this.$router.push(url);
    },
    change(index, data) {
      this.data = {
        ...this.data,
        ...data.data,
      };
      this.valid = {
        ...this.valid,
        [index]: data.valid,
      };
    },
    setupHeightAnimation(cb) {
      this.$refs.container.$el.scrollTo(0, 0);
      this.heightAnimation = true;
      this.heightStyle = {
        height: `${this.$refs.content.offsetHeight + 10}px`,
      };
      this.$nextTick(cb);
    },
    runHeightAnimation() {
      // Wait for slide transition
      setTimeout(() => {
        this.heightStyle = {
          height: `${this.$refs.content.offsetHeight + 10}px`,
        };

        // Wait for height transition
        setTimeout(() => this.afterHeightAnimation(), 300);
      }, 350);
    },
    afterHeightAnimation() {
      this.heightAnimation = false;
      this.heightStyle = {};
    },
    prevSlide() {
      this.setupHeightAnimation(() => {
        let nextStep = this.step - 1;
        while (this.shouldSkipSlide(nextStep)) {
          nextStep--;
        }
        this.step = nextStep;
        this.runHeightAnimation();
        this.onSlideOpen(nextStep);
      });
    },
    nextSlide() {
      this.setupHeightAnimation(() => {
        let nextStep = this.step + 1;
        while (this.shouldSkipSlide(nextStep)) {
          nextStep++;
        }
        this.step = nextStep;
        this.runHeightAnimation();
        this.onSlideOpen(nextStep);
      });
    },
    async fetchPhotoUploadKey() {
      this.photoUploadKey = (await api.getUploadKey()).key;
    },
    async addDamage() {
      this.loading = true;
      try {
        let { data } = this;

        data.requestType = constants.requestType.turbo;

        if (this.isPolish) {
          data.repairType = constants.repairType.professional;
          data.damages[0].type = data._polishType;
          delete data._polishType;
          delete data.damageLocation;
        }

        const images = data.images;

        // Create Damage
        const createdDamageId = await api.addDamage({
          ...this.data,
          images: undefined,
        });

        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Upload Images to Damage
        try {
          const [mainImage, ...restImages] = images;
          await api.addDamageImage(createdDamageId, mainImage);
          for (const image of restImages) {
            await api.addDamageImage(createdDamageId, image);
          }
        } catch (error) {
          console.error(error);
          await api.deleteDamage(createdDamageId);
          throw new Error("Failed to upload photos.");
        }

        this.createdDamageId = createdDamageId;
        this.$store.dispatch(FETCH_DAMAGES);
      } catch (error) {
        this.error = error;
      }
      this.loading = false;
    },
    close() {
      this.step = 1;
      this.data = {};
      this.valid = {};
      this.photoUploadKey = null;
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.add-damage-dialog-slides {
  width: 100%;
  padding: 10px 40px 0 40px;

  span {
    word-break: normal;
  }

  &.last-slide {
    padding: 0;
  }

  &.height-animate-active {
    overflow: hidden;
    transition: height ease 0.3s;
  }
}
.button-wrap {
  margin-top: 30px;
  text-align: right;
}

@media screen and (max-width: 740px) {
  .add-damage-dialog-slides {
    padding: 10px 0 0 0;
    height: 80vh;
  }

  .button-wrap {
    position: absolute;
    bottom: 14px;
    left: 7px;
    right: 7px;
    display: flex;
    justify-content: center;
  }
}
</style>

<style lang="scss">
.el-dialog.add-damage-dialog {
  width: 640px;
}

.el-dialog__body {
  word-break: break-word;

  .progress-wrap {
    position: absolute;
    top: 9px;
    left: 0;
    right: 45px;
  }
}

.el-dialog__header {
  button {
    top: 14px;
    right: 14px;
    width: 32px;
    height: 32px;
  }
}

@media screen and (max-width: 740px) {
  div.el-dialog.add-damage-dialog {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    margin: 0 !important;
    position: relative;
  }
}
</style>
