<template>
  <div>
    <div class="container">
      <h2>Wo willst du das Auto reparieren lassen?</h2>
      <PlaceAutocomplete label="PLZ/Ort" :value.sync="place" />
      <h3>Umkreis:</h3>
      <ClickableOptions :options="distanceOptions" :selected.sync="distance" />
    </div>
  </div>
</template>

<script>
import ClickableOptions from "@/components/ClickableOptions";
import PlaceAutocomplete from "@/components/PlaceAutocomplete";

export default {
  name: "LocationDetailsSlide",
  data() {
    return {
      place: {},
      distance: 100,
      distanceOptions: [
        { key: 15, title: "15 km", disabled: true },
        { key: 30, title: "30 km", disabled: true },
        { key: 50, title: "50 km" },
        { key: 100, title: "100 km" },
        { key: 300, title: "100+ km" }
      ]
    };
  },
  computed: {
    data() {
      const placeId = this.place && this.place.placeId;
      return {
        placeId,
        distance: this.distance
      };
    }
  },
  watch: {
    async data() {
      const { data } = this;
      const valid = !!data.placeId;

      this.$emit("change", {
        valid,
        data
      });
    }
  },
  components: {
    ClickableOptions,
    PlaceAutocomplete
  }
};
</script>

<style scoped lang="scss">
h2 {
  margin: 0;
  padding-bottom: 15px;
}
h3 {
  font-weight: normal;
  margin: 30px 0 5px 0;
}
.container {
  padding-top: 10px;
  padding-bottom: 25px;
}
.plz-field {
  width: 200px;
  text-align: right;
  padding-right: 5px;
}
</style>
