import axios from "axios";
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer
} from "axios-extensions";

const files = {
  url: process.env.VUE_APP_FILES_URL,
  _axios: null,
  authToken: null,

  init() {
    this.updateAxiosInstance();
  },

  async axios() {
    await this._updateAxiosInstance();
    return this._axios;
  },

  updateAxiosInstance(user) {
    this.user = user;
    return this._updateAxiosInstance();
  },

  async _updateAxiosInstance() {
    const newToken = this.user && (await this.user.getIdToken());
    if (this.authToken === newToken) {
      return;
    }
    this.authToken = newToken;
    this._axios = axios.create({
      baseURL: this.url,
      headers: {
        authorization: this.authToken ? `Bearer ${this.authToken}` : undefined
      },
      adapter: throttleAdapterEnhancer(
        cacheAdapterEnhancer(axios.defaults.adapter)
      )
    });
  },

  async getImage(imageKey) {
    const axios = await this.axios();
    return (await axios.get(imageKey, { responseType: "blob" })).data;
  }
};

export default files;
