<template>
  <div class="ImageTargetSelect">
    <img draggable="false" ref="img" :src="image" @click="click" width="100%" />
    <span
      v-for="(item, index) in items"
      class="marker"
      :key="`${item.x}_${item.y}`"
      :style="markerLocations[index]"
      @click="markerHit(index)"
    ></span>
  </div>
</template>

<script>
const MARKER_SIZE = 24;
export default {
  name: "ImageTargetSelect",
  props: ["selected", "image"],
  data() {
    return {
      height: 0,
      width: 0,
      offsetLeft: 0,
      offsetTop: 0
    };
  },
  mounted() {
    this.$refs.img.onload = () => {
      this.updateDimensions();
    };
  },
  computed: {
    items() {
      if (!this.selected) return [];
      return Array.isArray(this.selected) ? this.selected : [this.selected];
    },
    markerLocations() {
      const { height, width, offsetLeft, offsetTop } = this;
      return (this.items || []).map(item =>
        this.markerLocationCSS(item, {
          height,
          width,
          offsetLeft,
          offsetTop
        })
      );
    }
  },
  watch: {
    selected() {
      this.updateDimensions();
    }
  },
  methods: {
    click(e) {
      const { offsetX, offsetY } = e;
      const { height, width } = e.target;
      this.$emit("select", {
        x: offsetX / width,
        y: offsetY / height
      });
    },
    markerHit(index) {
      this.$emit("markerHit", index);
    },
    updateDimensions() {
      if (!this.$refs.img) {
        return;
      }
      const { height, width, offsetLeft, offsetTop } = this.$refs.img;
      this.height = height;
      this.width = width;
      this.offsetLeft = offsetLeft;
      this.offsetTop = offsetTop;
    },
    markerLocationCSS({ x, y }, { height, width, offsetLeft, offsetTop }) {
      const top = offsetTop + y * height - MARKER_SIZE / 2;
      const left = offsetLeft + x * width - MARKER_SIZE / 2;
      return {
        top: ((top / height) * 100).toFixed(4) + "%",
        left: ((left / width) * 100).toFixed(4) + "%"
      };
    }
  }
};
</script>

<style scoped lang="scss">
.ImageTargetSelect {
  position: relative;
  text-align: center;

  img {
    user-select: none;
  }

  .marker {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 4px solid white;
    background: rgb(255, 105, 105);
    box-shadow: 0 1px 10px 2px rgba(0, 0, 0, 0.705);
  }
}
</style>
