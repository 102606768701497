<template>
  <div class="notification-item" @click="react">
    <div class="round-icon-wrap">
      <md-icon>{{ icon }}</md-icon>
    </div>
    <div>
      <div class="subtext">
        {{ this.notification.createdAt | moment("from", "now") }}
        <span :class="{ 'new-indicator': 1, visible: !reacted }"></span>
      </div>
      <div class="headline">{{ title }}</div>
      <div class="car-datails" v-if="carRelatedNotification">
        {{ notification.data.carManufacturer }}&nbsp;
        {{ notification.data.carModel }}
      </div>
      <div class="car-datails" v-if="invalidationRelatedNotification">
        {{ notification.data.carManufacturer }}&nbsp;
        {{ notification.data.carModel }}
      </div>
    </div>
  </div>
</template>

<script>
import { MARK_NOTIFICATIONS_AS_REACTED } from "@/vuex/modules/notification/actions";
import notificationType from "../../constants/notificationType";
import repairStatus from "../../constants/repairStatus";

export default {
  name: "NotificationItem",
  props: ["notification"],
  computed: {
    seen() {
      return !!this.notification.seenAt;
    },

    reacted() {
      return !!this.notification.reactedAt;
    },

    title() {
      switch (this.notification.type) {
        case notificationType.newOfferOnDamage:
          return "Neues Angebot";
        case notificationType.newCounterOfferOnDamage:
          return "Neues Gegengebot";
        case notificationType.newTurboOfferOnDamage:
          return "Neues Turbo-Angebot";
        case notificationType.offerAccepted:
          return "Angebot angenommen";
        case notificationType.outBidCounterOffer:
          return "Überboten";
        case notificationType.counterOfferInvalidatedAndDeleted:
          return "Gegenangebot entfernt";
        case notificationType.repairStatusChange:
          return this.repairStatusTitle;
      }
      return "";
    },

    repairStatusTitle() {
      if (this.notification.type !== notificationType.repairStatusChange)
        return "";
      switch (this.notification.data.repairStatus) {
        case repairStatus.repairing:
          return "Fahrzeug angenommen";
        case repairStatus.readyToPickup:
          return "Fahrzeug abholbereit";
        case repairStatus.done:
          return "Fahrzeug wurde abgeholt";
      }
      return "";
    },

    carRelatedNotification() {
      return [
        notificationType.newOfferOnDamage,
        notificationType.newCounterOfferOnDamage,
        notificationType.newTurboOfferOnDamage,
        notificationType.offerAccepted,
        notificationType.outBidCounterOffer,
        notificationType.repairStatusChange
      ].includes(this.notification.type);
    },

    invalidationRelatedNotification() {
      return [notificationType.counterOfferInvalidatedAndDeleted].includes(
        this.notification.type
      );
    },

    icon() {
      switch (this.notification.type) {
        case notificationType.newOfferOnDamage:
          return "book";
        case notificationType.newCounterOfferOnDamage:
          return "replay";
        case notificationType.newTurboOfferOnDamage:
          return "whatshot";
        case notificationType.offerAccepted:
          return "check";
        case notificationType.repairStatusChange:
          return "car_repair";
        case notificationType.outBidCounterOffer:
        case notificationType.counterOfferInvalidatedAndDeleted:
          return "priority_high";
      }
      return "";
    }
  },
  methods: {
    react() {
      if (this.carRelatedNotification || this.invalidationRelatedNotification) {
        this.$router.push(`/damage/${this.notification.data.damageId}`);
      }
      this.$store.dispatch(MARK_NOTIFICATIONS_AS_REACTED, [
        this.notification._id
      ]);
    }
  }
};
</script>

<style scoped lang="scss">
.notification-item {
  padding: 10px 10px;
  margin: 3px 10px;
  width: 260px;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  transition: background-color ease 0.3s;
  border-radius: 6px;

  &:hover {
    background-color: #d7e6ff;
  }

  .car-datails {
    display: flex;
    font-size: 12px !important;
    justify-content: flex-start;
    align-items: center;
  }

  .round-icon-wrap {
    border: 3px solid transparent;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    font-size: 24px !important;
    margin: 7px 15px 0 5px;
    background-color: #d7e6ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .new-indicator {
    display: inline-block;
    width: 7px;
    height: 7px;
    margin: 1px 4px;
    border-radius: 50%;
    background: var(--md-theme-default-primary, #448aff);
    transition: ease 0.4s;
    opacity: 0;

    &.visible {
      opacity: 1;
    }
  }

  .subtext {
    color: gray;
    font-size: 12px;
    margin-bottom: 2px;
  }

  .headline {
    font-weight: light;
    font-size: 16px;
  }
}
</style>
