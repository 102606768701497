import constants from "@/constants";
import patchDamage from "./damage/patch";
import offerPatch from "./offers/patch";
import offerRemoved from "./offers/removed";
import removedDamage from "./damage/removed";
import refetchDamage from "./damage/refetch";
import pushNotification from "./notification/push";

export default {
  client: null,

  init(client) {
    this.client = client;
    this.addHandlers();
  },

  addHandlers() {
    this.client.on(constants.socketEvents.patchOffer, offerPatch);
    this.client.on(constants.socketEvents.refetchDamage, refetchDamage);
    this.client.on(constants.socketEvents.patchDamage, patchDamage);
    this.client.on(constants.socketEvents.removedDamage, removedDamage);
    this.client.on(constants.socketEvents.removedOffer, offerRemoved);
    this.client.on(constants.socketEvents.newNotification, pushNotification);
  }
};
