<template>
  <div @click="$emit('click')" class="field-button">
    <slot />
  </div>
</template>

<script>
export default {
  name: "FieldButton",
  props: ["options"]
};
</script>

<style scoped lang="scss">
.field-button {
  border-radius: 35px;
  padding: 3px 17px;
  margin: 3px 0;
  color: rgb(46, 46, 46);
  background: #b4b5b4;
  cursor: pointer;

  i {
    color: #b4b5b4 !important;
  }

  &.icon {
    padding: 0;
    background: transparent;

    &:hover {
      background: transparent;
      i {
        color: rgb(133, 133, 133) !important;
      }
    }
  }

  &:hover {
    background: rgb(206, 206, 206);
  }
}
</style>
