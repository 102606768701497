<template>
  <div :class="{ CollapsableCheckboxContainer: 1, disabled }">
    <md-checkbox v-model="isChecked" class="md-primary" :disabled="disabled">
      {{ title }}
      <div class="description">{{ description }}</div>
    </md-checkbox>
    <div class="content-wrap">
      <Collapsable :collapsed="!isChecked">
        <slot></slot>
      </Collapsable>
    </div>
  </div>
</template>

<script>
import Collapsable from "@/components/Collapsable";
export default {
  name: "CollapsableCheckboxContainer",
  props: ["checked", "title", "description", "disabled"],
  components: {
    Collapsable
  },
  computed: {
    isChecked: {
      get: function() {
        return this.checked;
      },
      set: function(checked) {
        this.$emit("change", checked);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.CollapsableCheckboxContainer {
  transition: opacity ease 0.3s;

  &.disabled {
    opacity: 0.45;
  }
}
.content-wrap {
  margin: 15px 20px 0 35px;
}
.description {
  color: gray;
  padding-bottom: 20px;
  font-size: 13px;
}
</style>
