<template>
  <div>
    <h2>Wo befindet sich der Schaden</h2>
    <ImageTargetSelect
      class="skizze"
      :image="image"
      @select="select"
      @markerHit="unselect"
      :selected="locations"
    />
    <p class="note">
      Bitte markieren sie auf dem Schaubild wo sich der Schaden ungefähr
      befindet.
    </p>
  </div>
</template>

<script>
import constants from "@/constants";
import ImageTargetSelect from "@/components/ImageTargetSelect";
import image from "@/assets/skizze.svg";
import image_rim from "@/assets/skizze_rim.svg";
export default {
  name: "DamageLocationSlide",
  props: ["damageType"],
  data() {
    return {
      locations: []
    };
  },
  components: {
    ImageTargetSelect
  },
  computed: {
    isMultiSelect() {
      return this.damageType === constants.damageType.rim;
    },
    image() {
      if (this.damageType === constants.damageType.rim) {
        return image_rim;
      }
      return image;
    }
  },
  watch: {
    locations() {
      const { locations } = this;
      const valid = locations.length > 0;
      this.$emit("change", {
        valid,
        data: {
          damageLocation: locations
        }
      });
    },
    isMultiSelect() {
      this.locations = [];
    },
    image() {
      this.locations = [];
    }
  },
  methods: {
    select(location) {
      if (!this.isMultiSelect) {
        this.locations = [location];
        return;
      }

      this.locations.push(location);
    },
    unselect(index) {
      const newLoctions = [...this.locations];
      newLoctions.splice(index, 1);
      console.log(index, newLoctions);
      this.locations = newLoctions;
    }
  }
};
</script>

<style scoped lang="scss">
h2 {
  margin: 0;
  padding-bottom: 35px;
}
.skizze {
  margin: 15px 0 0 0;
}
.note {
  margin-top: 40px;
  text-align: center;
  color: rgb(133, 133, 133);
  font-size: 14px;
}
</style>
