import Vue from "vue";
import NProgress from "nprogress";
import Router from "vue-router";
import Store from "./vuex/store";
import auth from "./services/auth";

Vue.use(Router);

const getIsLoaded = (state) => state.auth.isLoaded;

const queryAuth = async (to, from, next) => {
  const { token } = to.query;
  if (token) {
    await auth.loginWithToken(token);
    return next(to.path);
  }
  next();
};

const isLoaded = (to, from, next) => {
  if (getIsLoaded(Store.state)) return next();
  const unWatch = Store.watch(getIsLoaded, (value) => {
    unWatch();
    if (value) next();
  });
};

const needsAuth = (to, from, next) => {
  if (!Store.getters.isAuthenticated || !Store.getters.isEmailVerified) {
    return next("/");
  }
  return next();
};

const redirectOnAuth = (destination) => (to, from, next) => {
  if (Store.getters.isAuthenticated && Store.getters.isEmailVerified) {
    return next(destination);
  }
  return next();
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/upload/:key",
      name: "upload",
      meta: {
        noHeader: true,
      },
      component: () =>
        import(/* webpackChunkName: "upload" */ "./views/Upload.vue"),
    },
    {
      path: "/beta-invite",
      name: "beta-invite",
      meta: {
        noHeader: true,
      },
      component: () =>
        import(/* webpackChunkName: "beta-invite" */ "./views/BetaInvite.vue"),
    },
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/login",
      name: "login",
      meta: {
        noHeader: true,
      },
      component: () =>
        import(/* webpackChunkName: "login" */ "./views/LoginView"),
      beforeEnter: redirectOnAuth("/dashboard"),
    },
    {
      path: "/register",
      name: "register",
      meta: {
        noHeader: true,
      },
      component: () =>
        import(/* webpackChunkName: "register" */ "./views/RegisterView"),
      beforeEnter: redirectOnAuth("/dashboard"),
    },
    {
      path: "/dashboard",
      name: "dashboard",
      beforeEnter: needsAuth,
      component: () =>
        import(/* webpackChunkName: "dashboard" */ "./views/Dashboard"),
    },
    {
      path: "/my-account",
      name: "myaccount",
      beforeEnter: needsAuth,
      component: () =>
        import(/* webpackChunkName: "myaccount" */ "./views/MyAccount"),
      children: [
        {
          path: "",
          name: "details",
          component: () =>
            import(
              /* webpackChunkName: "myaccount" */ "@/views/MyAccount/Details"
            ),
        },
        {
          path: "garage",
          name: "garage",
          component: () =>
            import(
              /* webpackChunkName: "myaccount" */ "@/views/MyAccount/Garage"
            ),
        },
        {
          name: "security",
          path: "security",
          component: () =>
            import(
              /* webpackChunkName: "myaccount" */ "@/views/MyAccount/Security"
            ),
        },
        {
          name: "payment",
          path: "payment",
          component: () =>
            import(
              /* webpackChunkName: "myaccount" */ "@/views/MyAccount/Payment"
            ),
        },
      ],
    },
    {
      path: "/damage/:id",
      name: "damage",
      beforeEnter: needsAuth,
      component: () =>
        import(/* webpackChunkName: "dashboard" */ "./views/Damage.vue"),
    },
  ],
});

router.beforeEach(isLoaded);
router.beforeEach(queryAuth);

router.beforeResolve((to, _, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

router.afterEach(() => NProgress.done());

export default router;
