import Store from "../../../vuex/store";
import { PATCH_DAMAGE } from "../../../vuex/modules/damage/actions";
import { PATCH_DAMAGE_IN_LIST } from "../../../vuex/modules/damage-list/actions";
import { PATCH_DAMAGE_IN_LIST_FAVORITES } from "../../../vuex/modules/damage-list-favorites/actions";
import api from "../../api";

export default async function({ id }) {
  const inDamageList = !!Store.state.damageList.damages.find(
    damage => damage._id === id
  );
  const isDamage =
    Store.state.damage.damage && Store.state.damage.damage._id === id;

  if (inDamageList || isDamage) {
    // refetch damage
    const refetchedDamage = await api.getDamage(id);

    // update in store
    Store.dispatch(PATCH_DAMAGE, {
      damageId: id,
      payload: refetchedDamage,
      replace: true
    });
    Store.dispatch(PATCH_DAMAGE_IN_LIST, {
      damageId: id,
      payload: refetchedDamage,
      replace: true
    });
    Store.dispatch(PATCH_DAMAGE_IN_LIST_FAVORITES, {
      damageId: id,
      payload: refetchedDamage,
      replace: true
    });
  }
}
