import * as firebase from "firebase/app";
import {
  getAuth,
  EmailAuthProvider,
  updatePassword,
  updateEmail,
  onAuthStateChanged,
  sendPasswordResetEmail,
  reauthenticateWithCredential,
  signOut,
  sendEmailVerification,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithCustomToken,
} from "firebase/auth";
import Store from "../vuex/store";
import SocketService from "./socket";
import { AUTH_CHANGE, UPDATE_AUTH_USER } from "../vuex/modules/auth/actions";
import { FETCH_USER } from "../vuex/modules/userdata/actions";

const authService = {
  auth: null,

  init() {
    this.auth = getAuth(firebase.getApp());
    if (process.env.VUE_APP_FAKE_USER) {
      return Store.dispatch(AUTH_CHANGE, {
        uid: "xxx",
        getIdToken: () => Promise.resolve("XXXXX-FAKE_USER_ID_TOKEN-XXXXX"),
        updateEmail: () => console.error("Cannot perform action on fake user"),
        updatePassword: () =>
          console.error("Cannot perform action on fake user"),
        reauthenticateWithCredential: () =>
          console.error("Cannot perform action on fake user"),
        signInWithEmailAndPassword: () =>
          console.error("Cannot perform action on fake user"),
        createUserWithEmailAndPassword: () =>
          console.error("Cannot perform action on fake user"),
      });
    }
    onAuthStateChanged(this.auth, async (user) => {
      Store.dispatch(AUTH_CHANGE, user);
      if (user /*&& user.emailVerified*/) {
        SocketService.connect(user);
      } else {
        SocketService.disconnect();
      }
    });
  },

  sendPasswordResetEmail(email) {
    return sendPasswordResetEmail(this.auth, email);
  },

  getUser() {
    return Store.getters.authUser;
  },

  updateUserState() {
    Store.dispatch(UPDATE_AUTH_USER, this.auth.currentUser);
    Store.dispatch(FETCH_USER);
  },

  async changeEmail(email) {
    const user = this.getUser();
    await updateEmail(user, email);
    this.updateUserState();
  },

  async changePassword(password) {
    const user = this.getUser();
    await updatePassword(user, password);
    this.updateUserState();
  },

  reAuthenticate(password) {
    const user = this.getUser();
    const credentials = EmailAuthProvider.credential(user.email, password);
    return reauthenticateWithCredential(user, credentials);
  },

  login(email, password) {
    return signInWithEmailAndPassword(this.auth, email, password);
  },
  
  loginWithToken(token) {
    return signInWithCustomToken(this.auth, token);
  },

  async sendVerificationMail(email, password) {
    const user = this.getUser();
    await sendEmailVerification(user);
  },

  register(email, password) {
    return createUserWithEmailAndPassword(this.auth, email, password);
  },

  logout() {
    Store.dispatch(AUTH_CHANGE, null);
    return signOut(this.auth);
  },
};

export default authService;
