<template>
  <div class="wrap">
    <el-autocomplete
      class="autocomplete"
      v-model="input"
      :fetch-suggestions="getPlaces"
      :placeholder="label"
    ></el-autocomplete>
    <md-button
      :disabled="loadingGeo"
      @click="getLocation"
      class="md-icon-button"
    >
      <md-icon>my_location</md-icon>
    </md-button>
    <md-progress-spinner
      :style="{ position: 'absolute', right: '-24px' }"
      v-if="loadingGeo || loadingSuggestions"
      :md-diameter="24"
      :md-stroke="3"
      md-mode="indeterminate"
    ></md-progress-spinner>
  </div>
</template>

<script>
export default {
  name: "PlaceAutocomplete",
  props: ["value", "label"],
  data() {
    return {
      loadingGeo: false,
      loadingSuggestions: false,
      input: "",
      suggestions: [],
      autocompleteService: new window.google.maps.places.AutocompleteService(),
      autocompleteServiceSessionToken: new window.google.maps.places.AutocompleteSessionToken(),
      geocoder: new window.google.maps.Geocoder()
    };
  },
  computed: {
    selectedItem() {
      return (
        (this.suggestions || []).find(s => s.description === this.input) || null
      );
    }
  },
  watch: {
    selectedItem() {
      this.$emit(
        "update:value",
        this.selectedItem && {
          placeId: this.selectedItem.place_id
        }
      );
    }
  },
  methods: {
    getLocation() {
      if (window.navigator.geolocation) {
        this.loadingGeo = true;
        window.navigator.geolocation.getCurrentPosition(location => {
          this.suggestions = [];
          this.input = "";
          const { latitude, longitude } = location.coords;
          const latLng = new window.google.maps.LatLng(latitude, longitude);
          this.geocoder.geocode(
            {
              latLng
            },
            results => {
              const result = results.find(({ types }) =>
                types.includes("postal_code")
              );
              if (result) {
                this.suggestions = [
                  {
                    description: result.formatted_address,
                    place_id: result.place_id
                  }
                ];
                this.input = result.formatted_address;
              }
              this.loadingGeo = false;
            }
          );
        });
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    },
    getPlaces(input, cb) {
      if (!input) {
        this.suggestions = [];
        cb([]);
        return;
      }

      this.loadingSuggestions = true;
      this.autocompleteService.getPlacePredictions(
        {
          types: ["geocode"],
          componentRestrictions: { country: "de" },
          sessionToken: this.autocompleteServiceSessionToken,
          input
        },
        (results, status) => {
          console.log(results);
          this.loadingSuggestions = false;
          if (status === "OK") {
            this.suggestions = results;
            cb(results.map(r => ({ value: r.description, data: r })));
          } else {
            cb([]);
            console.error({ results, status });
          }
        }
      );
    }
  }
};
</script>

<style scoped lang="scss">
.wrap {
  display: flex;
  align-items: center;
  width: 100%;
}

.autocomplete {
  width: 100%;
}
</style>
