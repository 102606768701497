import io from "socket.io-client";
import socketLiveUpdate from "./socket-live-update";

export default {
  url: process.env.VUE_APP_SOCKETIO_URL,
  client: null,
  user: null,

  async connect(user) {
    this.user = user;
    this.disconnect();
    const token = await this.user.getIdToken();
    this.client = io(this.url, {
      query: `token=${encodeURIComponent(token)}`,
      secure: /^https:\/\//.test(this.url),
      transports: ["websocket"]
    });
    socketLiveUpdate.init(this.client);
  },

  disconnect() {
    if (this.client) {
      this.client.disconnect();
    }
    this.client = null;
  },

  get() {
    return this.client;
  }
};
