<template>
  <div>
    <div
      @click="select(option.key, null)"
      v-for="option in options"
      :key="option.key"
      :class="{
        item: 1,
        selected: selected === option.key,
        disabled: option.disabled,
      }"
    >
      <md-checkbox
        v-model="checked[option.key]"
        @change="option.disabled ? null : select(option.key, $event)"
        class="md-primary"
      >
        {{ option.title }}
      </md-checkbox>
      <div class="description">{{ option.description }}</div>
      <div class="link" v-if="option.readMoreLink">
        <a target="_blank" :href="option.readMoreLink.url">{{
          option.readMoreLink.text
        }}</a>
      </div>
      <div class="note" v-if="option.note">
        {{ option.note }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClickableOptionBoxes",
  props: ["options", "selected"],
  data() {
    return {
      checked: {},
    };
  },
  watch: {
    selected() {
      this.checked = this.options.reduce((res, option) => {
        return {
          ...res,
          [option.key]: this.selected === option.key,
        };
      }, {});
    },
  },
  methods: {
    select(key, event) {
      if (event === false || (event === null && this.checked[key])) {
        this.checked = {
          ...this.checked,
          [key]: true,
        };
        return;
      }
      this.$emit("change", key);
    },
  },
};
</script>

<style scoped lang="scss">
.item {
  position: relative;
  display: block;
  padding: 5px 18px 20px 18px;
  border: 3px solid rgb(211, 211, 211);
  border-radius: 10px;
  margin: 5px 10px 15px 0;
  cursor: pointer;
  transition: all ease 0.3s;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    border: 3px solid var(--md-theme-default-primary, #448aff);
  }

  &.selected {
    color: var(--md-theme-default-primary, #448aff);
    border: 3px solid var(--md-theme-default-primary, #448aff);
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .description {
    color: gray;
    padding: 0 0 0 35px;
    font-size: 13px;
    line-height: 20px;
    font-weight: normal;
  }

  .link {
    color: gray;
    padding: 0 0 0 35px;
    font-size: 13px;
    line-height: 20px;
    font-weight: normal;
  }

  .note {
    position: absolute;
    right: 3px;
    top: 3px;
    padding: 5px 10px;
    background: var(--md-theme-default-primary, #448aff);
    color: white;
    border-radius: 9px;
    font-size: 12px;
  }
}
</style>
