<template>
  <div class="progress-wrap">
    <div :style="{ width: `${progress}%` }" class="progress"></div>
    <div
      :style="{
        left,
      }"
      class="progress-label"
    >
      {{ Math.ceil(progress) }}%
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressLoader",
  props: ["progress"],
  computed: {
    left() {
      const { progress } = this;
      if (progress > 5 && progress < 90) return `calc(${progress}% - 15px)`;
      if (progress > 90) return `calc(${progress}% - 30px)`;
      return `-5px`;
    },
  },
};
</script>

<style scoped lang="scss">
.progress-wrap {
  position: relative;
  margin: 16px 15px 20px 15px;
  border-radius: 12px;
  height: 7px;
  background: rgb(226, 226, 226);

  .progress {
    background: var(--md-theme-default-primary, #448aff);
    height: 100%;
    transition: width ease 0.25s;
    border-radius: 12px;
  }

  .progress-label {
    position: absolute;
    text-align: center;
    bottom: -20px;
    height: 20px;
    width: 30px;
    font-size: 12px;
    color: rgb(179, 179, 179);
    transition: left ease 0.25s;
  }
}
</style>
