<template>
  <div>
    <h2>Welcher Schaden ist vorhanden?</h2>
    <CollapsableCheckboxContainer
      title="Kratzer"
      description="An ihrem Wagen ist ein Kratzer den sie reparieren lassen möchten."
      @change="change('hasScratch', $event)"
      :checked="hasScratch"
      :disabled="isAvailable.hasScratch"
    >
      <div class="item-details">
        <div class="headline">Tiefe:</div>
        <ClickableOptions
          :options="scratchDeepnessOptions"
          :selected.sync="scratchDeepnessSelectedOption"
        />
        <div class="input-flex-wrap">
          <div class="small-field">
            <md-field>
              <label>Länge (ca.)</label>
              <md-input v-model="scratchLength"></md-input>
              <span class="md-suffix">cm</span>
            </md-field>
          </div>
          <span></span>
          <md-field>
            <label>Lacknummer</label>
            <md-input v-model="scratchPaintNumber"></md-input>
            <FieldButton class="icon" @click="openPaintNumberHelp"
              ><md-icon>help</md-icon></FieldButton
            >
          </md-field>
        </div>
      </div>
    </CollapsableCheckboxContainer>
    <CollapsableCheckboxContainer
      title="Delle"
      description="An ihrem Wagen ist eine Delle die sie reparieren lassen möchten."
      @change="change('hasDent', $event)"
      :checked="hasDent"
      :disabled="isAvailable.hasDent"
    >
      <div class="item-details">
        <div class="headline">Durchmesser:</div>
        <ClickableOptions
          :options="dentSizeOptions"
          :selected.sync="dentSizeSelectedOption"
        />
      </div>
    </CollapsableCheckboxContainer>
    <CollapsableCheckboxContainer
      title="Beule"
      description="An ihrem Wagen ist eine Beule die sie reparieren lassen möchten."
      @change="change('hasBump', $event)"
      :checked="hasBump"
      :disabled="isAvailable.hasBump"
    >
      <div class="item-details">
        <div class="headline">Durchmesser:</div>
        <ClickableOptions
          :options="bumpSizeOptions"
          :selected.sync="bumpSizeSelectedOption"
        />
      </div>
    </CollapsableCheckboxContainer>
    <CollapsableCheckboxContainer
      title="Felgen-Schaden"
      description="Sie möchten einen Schaden an Ihrer Felge reparieren lassen."
      @change="change('hasRimDamage', $event)"
      :checked="hasRimDamage"
      :disabled="isAvailable.hasRimDamage"
    ></CollapsableCheckboxContainer>
    <CollapsableCheckboxContainer
      title="Politur"
      description="Sie möchten Ihren Wagen polieren lassen."
      @change="change('shouldBePolished', $event)"
      :checked="shouldBePolished"
      :disabled="isAvailable.shouldBePolished"
    ></CollapsableCheckboxContainer>
  </div>
</template>

<script>
import constants from "@/constants";
import CollapsableCheckboxContainer from "@/components/CollapsableCheckboxContainer.vue";
import ClickableOptions from "@/components/ClickableOptions.vue";
import FieldButton from "@/components/FieldButton.vue";

const PAINT_HELP_URL =
  "http://www.autolack21.de/farbton-suche/farbcodes-finden.html";

const getCleanInt = str => {
  return /^[0-9]+$/.test(str) ? parseInt(str, 10) : null;
};

export default {
  name: "DamageTypeSlide",
  data() {
    return {
      hasScratch: false,
      hasDent: false,
      hasBump: false,
      hasRimDamage: false,
      shouldBePolished: false,

      scratchLength: "",
      scratchPaintNumber: "",
      scratchDeepnessSelectedOption: "superficial",
      scratchDeepnessOptions: [
        { key: "superficial", title: "Oberflächlich" },
        { key: "deep", title: "Tief" }
      ],
      dentSizeSelectedOption: "1-3",
      dentSizeOptions: [
        { key: "1-3", title: "1 - 3 cm" },
        { key: "5-10", title: "5 - 10 cm" },
        { key: "10-15", title: "10 - 15 cm" },
        { key: "15+", title: "mehr als 15 cm" }
      ],
      bumpSizeSelectedOption: "1-3",
      bumpSizeOptions: [
        { key: "1-3", title: "1 - 3 cm" },
        { key: "5-10", title: "5 - 10 cm" },
        { key: "10-15", title: "10 - 15 cm" },
        { key: "15+", title: "mehr als 15 cm" }
      ]
    };
  },
  watch: {
    data() {
      const { data } = this;

      const atleastOneSet = Object.keys(data).some(key => !!data[key]);

      const valid =
        atleastOneSet &&
        (!data.scratch ||
          (/^[0-9]{1,}$/.test(data.scratch.length) &&
            /^[A-Za-z0-9-]{2,}$/.test(data.scratch.paintNumber)));

      this.$emit("change", {
        valid,
        data: {
          damages: Object.keys(data)
            .reduce((res, key) => [...res, data[key]], [])
            .filter(d => d)
        }
      });
    }
  },
  computed: {
    data() {
      const result = {};
      const {
        hasScratch,
        hasDent,
        hasBump,
        hasRimDamage,
        shouldBePolished
      } = this;

      result.scratch = hasScratch
        ? {
            damageType: constants.damageType.scratch,
            length: getCleanInt(this.scratchLength),
            isDeep: this.scratchDeepnessSelectedOption === "deep",
            paintNumber: this.scratchPaintNumber
          }
        : null;

      result.dent = hasDent
        ? {
            damageType: constants.damageType.dent,
            size: this.dentSizeSelectedOption
          }
        : null;

      result.bump = hasBump
        ? {
            damageType: constants.damageType.bump,
            size: this.bumpSizeSelectedOption
          }
        : null;

      result.rim = hasRimDamage
        ? { damageType: constants.damageType.rim }
        : null;
      result.polish = shouldBePolished
        ? { damageType: constants.damageType.polish }
        : null;

      return result;
    },
    isAvailable() {
      return {
        hasScratch: !(!this.hasRimDamage && !this.shouldBePolished),
        hasDent: !(
          !this.hasRimDamage &&
          !this.shouldBePolished &&
          !this.hasBump
        ),
        hasBump: !(
          !this.hasRimDamage &&
          !this.shouldBePolished &&
          !this.hasDent
        ),
        hasRimDamage: !(
          !this.hasScratch &&
          !this.hasBump &&
          !this.hasDent &&
          !this.shouldBePolished
        ),
        shouldBePolished: !(
          !this.hasScratch &&
          !this.hasBump &&
          !this.hasDent &&
          !this.hasRimDamage
        )
      };
    }
  },
  methods: {
    change(key, val) {
      this[key] = val;
    },
    openPaintNumberHelp() {
      window.open(PAINT_HELP_URL, "_blank");
    }
  },
  components: {
    CollapsableCheckboxContainer,
    ClickableOptions,
    FieldButton
  }
};
</script>

<style scoped lang="scss">
h2 {
  margin: 0;
  padding-bottom: 20px;
}
.headline {
  margin: 2px 0;
}
.item-details {
  padding-bottom: 40px;
}
.input-flex-wrap {
  display: flex;
  margin-top: 5px;

  & > span {
    width: 30px;
  }
}
.small-field {
  flex-shrink: 0;

  input {
    width: 105px;
    min-width: 105px;
    text-align: right;
    padding-right: 5px;
    word-break: normal;
    white-space: nowrap;
  }

  .md-suffix {
    flex-shrink: 0;
  }
}
</style>
