<template>
  <div class="notifications">
    <md-menu
      :md-offset-x="-240"
      :md-offset-y="0"
      :md-close-on-click="true"
      @md-closed="menuClosed"
      @md-opened="menuOpened"
    >
      <span :class="{ counter: 1, visible: counter > 0, attention }">{{
        counter
      }}</span>

      <md-button class="md-icon-button" md-menu-trigger>
        <md-icon>notifications</md-icon>
      </md-button>

      <md-menu-content class="menu-content">
        <div class="notifications-empty-state" v-if="notifications.length == 0">
          <md-icon>info</md-icon>
          Keine Benachrichtigungen
        </div>
        <div ref="notifications-wrapper">
          <NotificationItem
            v-for="notification in notifications"
            :key="notification._id"
            :notification="notification"
          />
        </div>
      </md-menu-content>
    </md-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  FETCH_NOTIFCATIONS,
  MARK_NOTIFICATIONS_AS_READ
} from "@/vuex/modules/notification/actions";
import NotificationItem from "./item";

export default {
  name: "Notifications",
  data() {
    return {
      markReadTimeout: null,
      attention: false
    };
  },
  computed: {
    ...mapGetters(["notifications"]),
    counter() {
      return this.notifications.filter(n => !n.seenAt).length;
    }
  },
  watch: {
    counter(curr, last) {
      if (curr > 0 && last == 0) {
        this.attention = true;
        setTimeout(() => {
          this.attention = false;
        }, 1000);
      }
    }
  },
  created() {
    this.$store.dispatch(FETCH_NOTIFCATIONS);
  },
  components: {
    NotificationItem
  },
  methods: {
    markAllItemsAsRead() {
      if (this.notifications.length == 0) {
        return;
      }
      this.$store.dispatch(
        MARK_NOTIFICATIONS_AS_READ,
        this.notifications.map(n => n._id)
      );
    },
    menuClosed() {
      if (this.markReadTimeout) {
        clearTimeout(this.markReadTimeout);
      }
    },
    menuOpened() {
      this.markReadTimeout = setTimeout(() => {
        this.markReadTimeout = null;
        this.markAllItemsAsRead();
      }, 300);
    }
  }
};
</script>

<style scoped lang="scss">
@keyframes attention {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.2);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.2);
  }

  70% {
    transform: scale(1);
  }
}

.attention {
  animation-name: attention;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
}

.notifications-empty-state {
  display: block;
  padding: 10px 20px;
  width: 270px;
  color: #989898;
  cursor: default;

  i {
    margin: 0 10px 0 5px;
    color: #989898 !important;
  }
}

.notifications {
  position: relative;

  .counter {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: bold;
    color: white;
    right: 0;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    background: var(--md-theme-default-primary, #448aff);
    transform: scale(0.4);
    opacity: 0;
    transition: ease 0.4s;

    &.visible {
      transform: scale(1);
      opacity: 1;
    }
  }
}
</style>
