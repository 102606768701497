import { OPEN_ADD_DAMAGE_DIALOG } from "./actions";
import { SET_DATA } from "./mutations";

const state = {
  data: {
    addDamageDialogOpen: false
  }
};

const actions = {
  async [OPEN_ADD_DAMAGE_DIALOG](context, state = true) {
    context.commit(SET_DATA, { addDamageDialogOpen: state });
  }
};

const mutations = {
  [SET_DATA](state, data) {
    state.data = {
      ...state.data,
      ...data
    };
  }
};

export default {
  state,
  actions,
  mutations
};
