import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7bfb65b1"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=7bfb65b1&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_prettier@3.1.1_vue-template-compiler@2.7.16_webpack@5.90.3/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports