<template>
  <div class="error">
    <h1>😅 Ouups, da ist etwas schief gelaufen ...</h1>
    <h2>Bitte versuche es später erneut.</h2>
  </div>
</template>

<script>
export default {
  name: "Error"
};
</script>

<style scoped lang="scss">
.error {
  margin: 240px 0;
  cursor: default;
  text-align: center;

  h1 {
    font-size: 32px;
  }

  h2 {
    color: #2c3e509c;
  }
}
</style>
